import { IDisposable } from '../../../shared/src/common.js';
import { DiscreteGridComponent } from '../../../shared/src/ecs/discrete-grid-component.js';
import { Ecs } from '../../../shared/src/ecs/ecs.js';
import { EntityComponentGroup } from '../../../shared/src/ecs/entityComponentGroup.js';
import { TransformComponent } from '../../../shared/src/ecs/transform-component.js';
import { System } from '../../../shared/src/ecs/system.js';
import { Logger } from '../../../shared/src/Logger.js';
import { Sprite } from '../graphics/sprite.js';
import { SpriteComponent } from './sokoban-render-system.js';
import { SokobanWorld } from './sokoban-world.js';

export class GridSystem extends System implements IDisposable {
    private stuff: EntityComponentGroup;
    private gridCellSize: number;

    constructor(private ecs: Ecs, private world: SokobanWorld, logger: Logger) {
        super(logger);
        this.gridCellSize = world.gridCellSize;
        this.entityAdded = this.entityAdded.bind(this);
        this.entityRemoved = this.entityRemoved.bind(this);
        this.stuff = new EntityComponentGroup(ecs.entityManager, [DiscreteGridComponent, TransformComponent]);
        this.stuff.entityAdded.subscribe(this.entityAdded);
        this.stuff.entityRemoved.subscribe(this.entityRemoved);
    }

    dispose() {
        this.stuff.entityAdded.unsubscribe(this.entityAdded);
        this.stuff.entityRemoved.unsubscribe(this.entityRemoved);
    }

    private entityAdded(entityId: number) {
        const gridPosition = this.ecs.entityManager.getEntity(entityId)!.getComponent<DiscreteGridComponent>(DiscreteGridComponent)!;
        gridPosition.elapsedTime = 0;
        const position = this.ecs.entityManager.getEntity(entityId)!.getComponent<TransformComponent>(TransformComponent)!;
        const conv = this.world.convertGridToWorld(gridPosition.cx, gridPosition.cy);
        position.x = conv.x;
        position.y = conv.y;
        this.ecs.entityManager.getComponentManager().markComponentDirty(entityId, TransformComponent);
    }

    private entityRemoved(entityId: number) {
    }
}
