import { SmartBuffer } from 'smart-buffer';

export class SokobanImmovableComponent {

    constructor() {
    }

    serialize(smartBuffer: SmartBuffer) {
    }

    static deserialize(smartBuffer: SmartBuffer) {
        const ne = new SokobanImmovableComponent();
        return ne;
    }
}

