import { assertNotNull } from '../../../shared/src/common.js';
import { Logger } from '../../../shared/src/Logger.js';
import { InputSystemEvent } from './input-system.js';

import { UiContext } from './ui-context.js';
import { UiContainer } from './ui-container.js';


export class UiEngine {
    private uicontainer: UiContainer;
    private scaleFactor: number;
    private _uiContext: UiContext;

    public constructor(uiContext: UiContext, private logger: Logger) {
        assertNotNull(logger, 'logger');
        this._uiContext = uiContext;
        this.scaleFactor = 1;

        this.uicontainer = new UiContainer(this._uiContext);
        this.uicontainer.width = this._uiContext.viewportDimension.width;
        this.uicontainer.height = this._uiContext.viewportDimension.height;

        this.updateScaleFactor = this.updateScaleFactor.bind(this);
        this._uiContext.canvas.resizeEvent.subscribe(this.resizeCanvas.bind(this));
        this.resizeCanvas();
    }

    get root(): UiContainer {
        return this.uicontainer;
    }

    dispose(): void {
    }

    resizeCanvas(): void {
        this.updateScaleFactor();
        console.log('ui system', this._uiContext.canvas.viewportDimension.width, this._uiContext.canvas.viewportDimension.height, this.scaleFactor);
        this._uiContext.scaleFactor = this.scaleFactor;
        this.uicontainer.width = this._uiContext.canvas.viewportDimension.width;
        this.uicontainer.height = this._uiContext.canvas.viewportDimension.height;
        this.uicontainer.invalidate();
    }

    private updateScaleFactor(): void {
        const referenceWidth = 412; // Set this to your reference resolution width
        const referenceHeight = 915; // Set this to your reference resolution height

        const scaleX = this._uiContext.canvas.viewportDimension.width / referenceWidth;
        const scaleY = this._uiContext.canvas.viewportDimension.height / referenceHeight;
        this.scaleFactor = Math.min(scaleX, scaleY); // Use the smaller scale to maintain aspect ratio

        console.log(`scale factor: ${this.scaleFactor} ${this._uiContext.canvas.viewportDimension.width} ${this._uiContext.canvas.viewportDimension.height}`);
    }

    render(): void {
        this._uiContext.canvasContext.resetTransform();
        this.uicontainer.render();
    }

    onInputEvent(event: InputSystemEvent): boolean {
        return this.uicontainer.handleInputEvent(event);
    }
}


