import {EventEmitter} from '../eventEmitter.js';
import {EntityManager} from './entityManager.js';
import {Constructor} from './ecs.js';

export class EntityComponentGroup {
    entityAdded: EventEmitter<number> = new EventEmitter();
    entityRemoved: EventEmitter<number> = new EventEmitter();
    notifiedEntities: Set<number> = new Set();

    constructor(private entityManager: EntityManager, private componentTypes: Constructor[]) {
        this.componentAdded = this.componentAdded.bind(this);
        this.componentRemoved = this.componentRemoved.bind(this);
        this.entityManager.componentAdded.subscribe(this.componentAdded);
        this.entityManager.componentRemoved.subscribe(this.componentRemoved);
    }

    private componentAdded(eventArgs: { entityId: number, componentType: Constructor }) {
        if (this.notifiedEntities.has(eventArgs.entityId)) {
            return;
        }
        if (this.componentTypes.includes(eventArgs.componentType)) {
            const entity = this.entityManager.getEntity(eventArgs.entityId)!;
            if (this.componentTypes.every(componentType => entity.hasComponent(componentType))) {
                this.entityAdded.notify(eventArgs.entityId);
                this.notifiedEntities.add(eventArgs.entityId);
            }
        }
    }

    private componentRemoved(eventArgs: { entityId: number, componentType: Constructor }) {
        if (!this.notifiedEntities.has(eventArgs.entityId)) {
            return;
        }
        if (this.componentTypes.includes(eventArgs.componentType)) {
            this.entityRemoved.notify(eventArgs.entityId);
            this.notifiedEntities.delete(eventArgs.entityId);
        }
    }
}
