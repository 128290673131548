import {setUserFromToken, UserType} from '@thomaszeman/web-common/auth';
import {h} from 'preact';
import {Route, Router} from 'preact-router';
import {useCallback, useEffect, useState} from 'preact/hooks';
import authWall from './auth-wall.js';
import {LocalGameScreen} from './local-game-screen.js';
import {NotFound} from './not-found-page.js';
import {authApiClient} from './sokoban/sokoban-api-client.js';
import {setup} from 'goober';
import {WelcomeScreen} from './menus/welcome-screen.js';
import {GlobalContext} from './global-context.js';

setup(h);

const readUserFromLocalStorage = (): UserType | null => {
    const user = localStorage.getItem('user');
    if (!user) {
        return null;
    }
    return JSON.parse(user);
}

const writeUserToLocalStorage = (user: UserType | null) => {
    if (!user) {
        localStorage.removeItem('user');
    } else {
        localStorage.setItem('user', JSON.stringify(user));
    }
}

export const App = () => {
    const [user, _setUser] = useState<UserType | null>(readUserFromLocalStorage);

    const setUserFromAuth = useCallback(async () => {
        const accessToken = await authApiClient.getCurrentOrNewAccessToken();
        const idToken = await authApiClient.getCurrentOrNewIdToken();
        setUserFromToken(accessToken, idToken, (_) => {
            _setUser(_);
            writeUserToLocalStorage(_);
        });
    }, []);

    useEffect(() => {
        (async () => {
            const isAuthenticated = await authApiClient.getAuthStatus();
            if (!isAuthenticated) {
                // we are not authenticated, but we have a user in local storage
                if (user) {
                    console.log('User in local storage, but not authenticated');
                    _setUser(null);
                }
                console.log('No user in local storage and not authenticated');
                return;
            }
            if (!user) {
                // we are authenticated, but we have no user in local storage
                console.log('No user in local storage, but authenticated');
                await setUserFromAuth();
            }
        })()
    }, []);

    useEffect(() => {
        const handlePopState = () => {
            window.location.reload();
        };

        window.addEventListener('popstate', handlePopState);
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    return (
        <GlobalContext.Provider value={
            {
                user,
                updateUser: () => {
                    setUserFromAuth().catch((e) => {
                        console.error(e);
                    });
                },
            }
        }>
            <Router>
                <Route path="/:setName/:puzzleNoStr" component={LocalGameScreen}/>
                <Route path="/" component={authWall(WelcomeScreen)}/>
                <Route default component={NotFound}/>
            </Router>
        </GlobalContext.Provider>
    );
};
