import { IDisposable, Queue } from '../../../shared/src/common.js';
import { Ecs } from '../../../shared/src/ecs/ecs.js';
import { System } from '../../../shared/src/ecs/system.js';
import { Logger } from '../../../shared/src/Logger.js';
import { SokobanGameCommand } from './sokoban-game-command.js';
import { SokobanWorld } from './sokoban-world.js';

export const NewCommand = 'NewCommand';

export class SokobanGameCommandDispatcher extends System implements IDisposable {
    _queue = new Queue<SokobanGameCommand>()

    constructor(private ecs: Ecs, private world: SokobanWorld, logger: Logger) {
        super(logger);
        this.onNewCommand = this.onNewCommand.bind(this);
        ecs.eventBus.on(NewCommand, this.onNewCommand);
    }

    onNewCommand(onNewCommand: any) {
        this._queue.enqueue(onNewCommand);
    }

    dispose() {
        this.ecs.eventBus.off(NewCommand, this.onNewCommand);
    }

    update(deltaTime: number) {
        while (!this._queue.isEmpty()) {
            const command = this._queue.dequeue()!;
            command.execute(this.ecs.entityManager);
        }
    }
}
