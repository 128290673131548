import {styled} from 'goober';
import {h} from 'preact';
import {forwardRef} from 'preact/compat';
import {useEffect, useState} from 'preact/hooks';
import {loadProgress} from '../user-progress-persistence.js';
import {MenuContainer, SetButton, SetProgressLabel} from './welcome-screen-styled.js';

const SetButtonContainer = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const localPuzzles2 = [
    {setName: 'Microban', progress: 0, numberPuzzles: 155},
    {setName: 'Microban II', progress: 0, numberPuzzles: 155},
    {setName: 'Microban III', progress: 0, numberPuzzles: 155}];

export const LocalPuzzleSetSelection = ({onSelect}: {
    onSelect: (setName: string | null, puzzleNo: number) => void
}) => {
    const [localPuzzles, setLocalPuzzles] = useState(localPuzzles2);
    useEffect(() => {
// Convert localPuzzles array into maxPuzzleIds object
        const maxPuzzleIds = localPuzzles.reduce((accumulator, puzzleSet) => {
            accumulator[puzzleSet.setName] = puzzleSet.numberPuzzles;
            return accumulator;
        }, {} as { [setName: string]: number });

// Now use maxPuzzleIds with loadProgress
        (async () => {
            // Assuming loadProgress is an asynchronous function
            const loadedProgress = await loadProgress();
            if (loadedProgress) {
                for (const setName in loadedProgress.sets) {
                    const puzzleId = loadedProgress.sets[setName];
                    console.log(`Loaded progress for ${setName}: Puzzle ID ${puzzleId}`);
                    const setI = localPuzzles.find(puzzleSet => puzzleSet.setName.toLowerCase() === setName.toLowerCase());
                    if (setI) setI.progress = puzzleId;
                }
            } else {
                console.log("Progress has been reset due to tampering or invalid data.");
            }
            setLocalPuzzles([...localPuzzles]);
        })()
    }, []);

    return (
        <MenuContainer>
            {localPuzzles.map((puzzle, index) => (
                <SetButtonContainer>
                    <SetButton key={index}
                               onClick={() => onSelect(puzzle.setName.toLowerCase(), Math.max(puzzle.progress, 1))}>{puzzle.setName}</SetButton>
                    <SetProgressLabel>{puzzle.progress}/{puzzle.numberPuzzles}</SetProgressLabel>
                </SetButtonContainer>
            ))}
            <SetButton onClick={() => onSelect(null, 0)}>Back</SetButton>
        </MenuContainer>
    );
}
