import { InputEventType, InputSystemEvent } from './input-system.js';
import { isPointInsideRectangle, Rectangle2D } from './maths.js';
import { UiButton } from './ui-button.js';
import { UiContext } from './ui-context.js';

export class UiButtonSegmented extends UiButton {
    constructor(context: UiContext, image: string, private segments: { rect: Rectangle2D, name: string }[]) {
        super(context, image);
    }

    override handleInputEvent(event: InputSystemEvent): boolean {
        if (event.type === InputEventType.MouseDown && isPointInsideRectangle(event.position!, this.nRect)) {
            let localPosition = { x: event.position!.x - this.nRect.x, y: event.position!.y - this.nRect.y };
            localPosition = { x: localPosition.x * this.spriteScale, y: localPosition.y * this.spriteScale };
            for (const segment of this.segments) {
                console.log(`comparing ${this._context.scaleFactor} -- ${localPosition.x}, ${localPosition.y} to ${segment.rect.x}, ${segment.rect.y}, ${segment.rect.w}, ${segment.rect.h}`);
                if (isPointInsideRectangle(localPosition, segment.rect)) {
                    console.log('clicked', segment.name);
                    this.onClick.notify(segment.name);
                    return true;
                }
            }
        }
        return false;
    }
}
