import {EventEmitter} from '../../../shared/src/eventEmitter.js';
import {InputEventType, InputSystemEvent} from './input-system.js';
import {isPointInsideRectangle} from './maths.js';
import { UiContext } from './ui-context.js';
import { UiElement } from './ui-element.js';

export class UiLabel extends UiElement {
    private _text: string = '';
    private _textLines: string[] = [];
    //private _lineWidths: number[] = [];
    private _lineHeight: number = 1.5;
    private _fontSize: number;
    private _onClick: EventEmitter<string> = new EventEmitter<string>();

    get onClick(): EventEmitter<string> {
        return this._onClick;
    }

    get text(): string {
        return this._text;
    }

    set text(value: string) {
        if (this._text === value) return;
        this._text = value;
        this._textLines = value.split('\n');
        this.parent?.childDimensionsChanged(this);
        this._dirty = true;
    }

    get lineHeight(): number {
        return this._lineHeight;
    }

    set lineHeight(value: number) {
        this._lineHeight = value;
        this._dirty = true;
    }

    get fontSize(): number {
        return this._context.standardFontSize;
    }

    protected dimensionsChanged(): void {
        this._dirty = true;
    }

    constructor(context: UiContext) {
        super(context);
        this._fontSize = context.standardFontSize;
    }

    private calculateContentDimensions(): { width: number, height: number } {
        this._context.canvasContext.font = `${this.fontSize}px "Press Start 2P", system-ui`;
        const h = this.fontSize * this.lineHeight * this._textLines.length;
        if (this.width === 'auto') {
            this._textLines = this._text.split('\n');
            let w = 0;
            this._textLines.forEach((line, index) => {
                const w1 = Math.floor(this._context.canvasContext.measureText(line).width);
                w = Math.max(w, w1);
            });
            return { width: Math.round(w), height: Math.round(h) };
        } else {
            const maxWidth = Number.parseInt(this.width) * this._context.scaleFactor;
            let w = 0;
            this._textLines = [];
            const textLines = this._text.split('\n');
            textLines.forEach((line, index) => {
                let text = line;
                let width = this._context.canvasContext.measureText(text).width;
                let sliced = false;
                while (width > maxWidth) {
                    sliced = true;
                    text = text.slice(0, -1);
                    width = this._context.canvasContext.measureText(text + '..').width;
                }
                w = Math.max(w, width);
                if (sliced) text += '..';
                this._textLines.push(text);
            });
            return { width: Math.round(w), height: Math.round(h) };
        }
        throw new Error('Not implemented');
    }

    override layout() {
        super.layout();
        const res = this.calculateContentDimensions();
        this.calculatedWidth = res.width;
        this.calculatedHeight = res.height;
    }

    render(): void {
        if (this._dirty) {
            this._dirty = false;
        }
        const htmlContext = this._context.canvasContext;
        htmlContext.font = `${this.fontSize}px "Press Start 2P", system-ui`;
        htmlContext.fillStyle = 'white';
        htmlContext.textAlign = 'left';
        htmlContext.textBaseline = 'top';

        this._textLines.forEach((line, index) => {
           const yOffset = index * this.fontSize * this.lineHeight;
            this._context.canvasContext.fillText(line, this.position.x, this.position.y + yOffset);
        });
    }

    override handleInputEvent(event: InputSystemEvent): boolean {
        if (event.type === InputEventType.MouseDown && isPointInsideRectangle(event.position!, { x: this.position.x, y: this.position.y, w: this.calculatedWidth, h: this.calculatedHeight })) {
            this.onClick.notify('');
            return true;
        }
        return false;
    }
}
