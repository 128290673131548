import {styled} from 'goober';
import {h} from 'preact';

const CenteredContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`;

// NotFound Component
export const NotFound = () => {
    return (
        <CenteredContainer>
            <div  style={{ marginTop: '20px', color: 'white'}}>Not found</div>
            <a href="/" style={{ marginTop: '20px', color: 'blue', textDecoration: 'none' }}>Go back</a>
        </CenteredContainer>
    );
};
