import {IDisposable} from '../../../shared/src/common.js';
import {DiscreteGridComponent} from '../../../shared/src/ecs/discrete-grid-component.js';
import {Ecs} from '../../../shared/src/ecs/ecs.js';
import {TransformComponent} from '../../../shared/src/ecs/transform-component.js';
import {System} from '../../../shared/src/ecs/system.js';
import {Logger} from '../../../shared/src/Logger.js';
import {SokobanBoxComponent} from './sokoban-box-component.js';
import {SokobanGameInfoComponent} from './sokoban-game-info-component.js';
import {LevelCompletedCommand} from './sokoban-game-scene.js';
import {SpriteComponent} from './sokoban-render-system.js';
import {SokobanTargetComponent} from './sokoban-target-component.js';
import {SokobanWorld} from './sokoban-world.js';

export const EventMoveStarted = 'EventMoveStarted';
export const EventMoveFinished = 'EventMoveFinished';

function easeInOutQuad(t: number): number {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

export class SokobanMoveSystem extends System implements IDisposable {
    constructor(private ecs: Ecs, private world: SokobanWorld, logger: Logger) {
        super(logger);
    }

    dispose() {
    }

    checkAllBoxesInRightSpot() {
        const entities = this.ecs.entityManager.getEntitiesWithAnyComponentsOf(SokobanBoxComponent);
        for (let entity of entities) {
            const box = entity!.getComponent<SokobanBoxComponent>(SokobanBoxComponent);
            if (!box!.isInRightSpot) {
                return false;
            }
        }
        return true;
    }

    update(deltaTime: number) {
        const entities = this.ecs.entityManager.getEntitiesWithAnyComponentsOf(DiscreteGridComponent);
        for (let entity of entities) {
            const grid = entity!.getComponent<DiscreteGridComponent>(DiscreteGridComponent)!;
            const position = entity!.getComponent<TransformComponent>(TransformComponent)!;
            if (grid.cx !== grid.tx || grid.cy !== grid.ty) {
                if (grid.isAboutToMove()) {
                    this.ecs.eventBus.emit(EventMoveStarted, entity!.id);
                    const box = entity!.getComponent<SokobanBoxComponent>(SokobanBoxComponent);
                    const sprite = entity!.getComponent<SpriteComponent>(SpriteComponent)!;
                    if (box) {
                        //const allTargets = this.ecs.entityManager.getEntitiesWithAnyComponentsOf(SokobanTargetComponent);
                        // const targetFound = allTargets.find((e) => {
                        //     const targetPos = e.getComponent<DiscreteGridComponent>(DiscreteGridComponent)!;
                        //     return targetPos.cx === grid.cx && targetPos.cy === grid.cy;
                        // });

                        sprite.animation = 'idle';
                        entity!.setComponent(SpriteComponent, sprite);
                        box.isInRightSpot = false;
                    }
                    if (entity.name === 'player') {
                        if (grid.cx > grid.tx) {
                            sprite.animation = 'walk_left';
                        }
                        if (grid.cx < grid.tx) {
                            sprite.animation = 'walk_right';
                        }
                        if (grid.cy > grid.ty) {
                            sprite.animation = 'walk_up';
                        }
                        if (grid.cy < grid.ty) {
                            sprite.animation = 'walk_down';
                        }
                        entity!.setComponent(SpriteComponent, sprite);
                    }
                }

                // Calculate target and source world positions
                const targetWorld = this.world.convertGridToWorld(grid.tx, grid.ty);
                const sourceWorld = this.world.convertGridToWorld(grid.cx, grid.cy);

                // Calculate delta positions
                const deltaX = targetWorld.x - sourceWorld.x;
                const deltaY = targetWorld.y - sourceWorld.y;

                // Update elapsed time and calculate eased time
                grid.elapsedTime += deltaTime;
                const t = Math.min(grid.elapsedTime / grid.duration, 1);
                //console.log(`t: ${t} elapsedTime: ${grid.elapsedTime} duration: ${grid.duration}`);
                const easedT = easeInOutQuad(t); // Apply easing function

                // Interpolate position using eased time
                position.x = sourceWorld.x + deltaX * easedT;
                position.y = sourceWorld.y + deltaY * easedT;

                // Check if movement is complete
                if (t >= 1) {
                    // Snap to target position and update grid component
                    position.x = targetWorld.x;
                    position.y = targetWorld.y;
                    grid.cx = grid.tx;
                    grid.cy = grid.ty;
                    grid.elapsedTime = 0; // Reset elapsed time for next movement
                    this.ecs.eventBus.emit(EventMoveFinished, entity!.id);

                    const box = entity!.getComponent<SokobanBoxComponent>(SokobanBoxComponent);
                    if (box) {
                        const allTargets = this.ecs.entityManager.getEntitiesWithAnyComponentsOf(SokobanTargetComponent);
                        const targetFound = allTargets.find((e) => {
                            const targetPos = e.getComponent<DiscreteGridComponent>(DiscreteGridComponent)!;
                            return targetPos.cx === grid.cx && targetPos.cy === grid.cy;
                        });
                        if (targetFound) {
                            const sprite = entity!.getComponent<SpriteComponent>(SpriteComponent)!;
                            sprite.animation = 'onTarget';
                            entity!.setComponent(SpriteComponent, sprite);
                            box.isInRightSpot = true;
                            if (this.checkAllBoxesInRightSpot()) {
                                const stats = this.ecs.entityManager.getEntityByName('gameInfo')!.getComponent<SokobanGameInfoComponent>(SokobanGameInfoComponent)!;
                                stats.gameRunning = false;
                                this.ecs.eventBus.emit(LevelCompletedCommand, {
                                    steps: stats.steps,
                                    pushes: stats.pushes,
                                    time: stats.getElapsedTime(),
                                    solution: stats.solution,
                                });
                            }
                        } else {
                            const sprite = entity!.getComponent<SpriteComponent>(SpriteComponent)!;
                            sprite.animation = 'idle';
                            entity!.setComponent(SpriteComponent, sprite);
                            box.isInRightSpot = false;
                        }
                    }
                    if (entity.name === 'player') {
                        const sprite = entity!.getComponent<SpriteComponent>(SpriteComponent)!;
                        sprite.animation = 'idle' + sprite.animation.substring(4);
                        console.log(sprite.animation);
                        entity!.setComponent(SpriteComponent, sprite);
                    }

                }
                this.ecs.entityManager.getComponentManager().markComponentDirty(entity!.id, TransformComponent);
                this.ecs.entityManager.getComponentManager().markComponentDirty(entity!.id, DiscreteGridComponent);
            }
        }
    }
}
