import {EventBus} from '../../shared/src/eventBus.js';
import {LoggerImpl} from '../../shared/src/Logger.js';

export const globalEventBus = new EventBus(new LoggerImpl(), 'global');

export type EventWindowResize = {
    width: number;
    height: number;
}

export const EVENT_WINDOW_RESIZE = "window.resize";
export const EVENT_WINDOW_RESIZE_START = "window.resize.start";

// Only needed when this code runs as part of a backend only test
if (typeof window !== 'undefined') {
    setTimeout(() => {
        globalEventBus.emit(EVENT_WINDOW_RESIZE, {width: window.innerWidth, height: window.innerHeight});
    }, 250);

    let resizeTimeout: (number | NodeJS.Timeout | null) = null;
    window.addEventListener('resize', () => {
        if (resizeTimeout === null) {
            globalEventBus.emit(EVENT_WINDOW_RESIZE_START, {width: window.innerWidth, height: window.innerHeight});
        } else {
            clearTimeout(resizeTimeout);
        }
        resizeTimeout = setTimeout(() => {
            resizeTimeout = null;
            globalEventBus.emit(EVENT_WINDOW_RESIZE, {width: window.innerWidth, height: window.innerHeight})
        }, 250);
    });

    globalEventBus.onWithReplay(EVENT_WINDOW_RESIZE, (eventWindowResizeUnknown: unknown) => {
        const eventWindowResize = eventWindowResizeUnknown as EventWindowResize;
        if (typeof window !== 'undefined' && ((window as unknown as { isDebugMode: boolean }).isDebugMode)) {
            return;
        }
        if (eventWindowResize.height > eventWindowResize.width * 1.25) {
            // document.getElementById('resolutionNotSupportedContainer')!!.style.display = 'none';
            // document.getElementById('landscapeContainer')!!.style.display = 'none';
            // document.getElementById('portraitContainer')!!.style.display = 'block';
            return;
        }
        if (eventWindowResize.width > eventWindowResize.height * 1.25) {
            // document.getElementById('resolutionNotSupportedContainer')!!.style.display = 'none';
            // document.getElementById('landscapeContainer')!!.style.display = 'block';
            // document.getElementById('portraitContainer')!!.style.display = 'none';
            return;
        }
        //
        // document.getElementById('resolutionNotSupportedContainer')!!.style.display = 'block';
        // document.getElementById('portraitContainer')!!.style.display = 'none';
        // document.getElementById('landscapeContainer')!!.style.display = 'none';
    });
}
