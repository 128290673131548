import { EventEmitter } from '../../../shared/src/eventEmitter.js';
import { SpriteEntity } from './animation-manager.js';
import { InputEventType, InputSystemEvent } from './input-system.js';
import { isPointInsideRectangle, moveRectangle, Rectangle2D, scaleRectToHeight } from './maths.js';
import { Sprite } from './sprite.js';
import { UiContext } from './ui-context.js';
import { UiElement } from './ui-element.js';

export class UiButton extends UiElement {
    private sprite!: Sprite;
    private fsd: SpriteEntity;
    protected nRect: Rectangle2D;
    private asd!: number;
    private _onClick: EventEmitter<string> = new EventEmitter<string>();
    protected spriteScale: number = 1;

    get onClick(): EventEmitter<string> {
        return this._onClick;
    }

    constructor(context: UiContext, private image: string) {
        super(context);
        this.fsd = this._context.animationManager.getEntityAnimation(this.image);
        this.nRect = { x: 0, y: 0, w: 0, h: 0 };
    }

    override dimensionsChanged(): void {
        this.calculateRenderPosition();
    }

    override positionChanged(): void {
        this.calculateRenderPosition();
    }

    override layout() {
        super.layout();
        this.calculateRenderPosition();
    }

    calculateRenderPosition() {
        const position = this.position;
        this.asd = this.fsd.animation['idle'][0];
        const rect = this.fsd.spriteSheet.getFrameRect(this.asd);
        //console.log(`scaling to height: ${this.scaledHeight} ${this._context.scaleFactor} from ${this.height}`);
        let scale = 0;
        if (this._height === 'auto') {
            scale = this._context.scaleFactor;
        } else if (this._height.endsWith('px')) {
            scale = rect.h / parseInt(this._height) / this._context.scaleFactor;
        }
        this.spriteScale = scale;
        this.calculatedWidth = rect.w / scale;
        this.calculatedHeight = rect.h / scale;
        this.nRect = moveRectangle(scaleRectToHeight({
            x: 0,
            y: 0, ...rect,
        }, this.calculatedHeight), position.x, position.y);
    }

    render(): void {
        const hctx = this._context.canvasContext;
        this.fsd.spriteSheet!.renderSpriteInRect(hctx, this.asd, this.nRect);
    }

    override handleInputEvent(event: InputSystemEvent): boolean {
        if (event.type === InputEventType.MouseDown && isPointInsideRectangle(event.position!, this.nRect)) {
            this.onClick.notify('');
            return true;
        }
        return false;
    }
}
