import {styled} from 'goober';
import {h} from 'preact';
import {route} from 'preact-router';
import {forwardRef} from 'preact/compat';
import {useEffect, useState} from 'preact/hooks';
import {loadProgress} from '../user-progress-persistence.js';
import {CreditsComponent} from './credits-component.js';
import {ImageWithTextComponent} from './image-with-text-component.js';
import {FullScreenParticlesComponent} from './particle-component.js';
import {LocalPuzzleSetSelection} from './set-selection-component.js';

enum WelcomeScreenState {
    Welcome,
    LocalGame,
    OnlineGame,
    Credits
}

// Main container using Flexbox
const GameScreenContainer = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: white;
    font-family: 'Capture_it', system-ui;
    box-sizing: border-box;
    z-index: 100;
`;

const GameTitle = styled('div')`
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const GreetingAndOptions = styled('div')`
    width: 100%;
    margin-top: 500px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Greeting = styled('div')`
    margin-bottom: 20px;
    background-color: rgba(0, 0, 0, 0.5);

`;

const AnimatedGreeting = styled('div')`
    background: rgba(100, 0, 0, 0.5);
    position: absolute;
    top: 50px;
    width: 100%;
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    opacity: 0;
    transform: translateX(100%);
    color: white;

    &.active {
        opacity: 1;
        transform: translateX(10%);
    }
`;

const MenuButtons = styled('div')`
    position: absolute;
    top: 50%;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
`;

const MenuButton = styled('button', forwardRef)`
    padding: 10px 20px;
    margin: 10px 10px;
    background: #fbecd9;
    color: #654217;
    border: 3px solid #c18960;
    cursor: pointer;
    font-family: 'Capture_it', serif;
    font-size: 1.5rem;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
        background-color: white;
        color: black;
    }

    &:focus {
        outline: none;
    }
`;

const FooterText = styled('div')`
    position: absolute;
    bottom: 0;
    color: #888;
    width: 100%;
    display: flex;
    justify-content: center;
`;

function determineGreeting() {
    const hour = new Date().getHours();
    if (hour < 6 || hour > 22) return "Good night";
    if (hour < 10) return "Good morning";
    if (hour < 14) return "Good day";
    if (hour < 18) return "Good afternoon";
    return "Good evening";
}

interface MainGameScreenProps {
    playerName: string;
}

type Activity = {
    puzzleId: string,
    subject: string,
    timeToSolve: number,
    steps: number
}

const footerTextOptions = [
    'Made in Sydney 🦘',
    'Greets to Ukraine',
];


const VisibleDiv = styled('div')<{ visible: boolean }>`
    opacity: ${(props) => props.visible ? 1 : 0};
`;


const VisibleAfterTimeout = ({children}: { children: any }) => {
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 100);
        return () => clearTimeout(timer);
    });
    return <VisibleDiv visible={isVisible}>{children}</VisibleDiv>;
}

export const WelcomeScreen = (props: { playerName: string }) => {
    {
        const [welcomeScreenState, setWelcomeScreenState] = useState<WelcomeScreenState>(WelcomeScreenState.Welcome);
        const [activity, setActivity] = useState<Activity[]>([]);
        const [currentActivityIndex, setCurrentActivityIndex] = useState(0);

        // useEffect(() => {
        //     (async () => {
        //         const res = await getActivities();
        //         setActivity(res);
        //     })();
        //     const timer = setInterval(() => {
        //         setCurrentActivityIndex(prevIndex => (prevIndex + 1) % (activity.length || 1));
        //     }, 5000);
        //     return () => clearInterval(timer);
        // }, [activity.length]);

        const [footerText, setFooterText] = useState<string>(footerTextOptions[Math.floor(Math.random() * footerTextOptions.length)]);

        const selectDifficulty = (setName: string | null, puzzleNo: number) => {
            console.log(`Selected ${setName} puzzle ${puzzleNo}`);
            if (setName === null) {
                setWelcomeScreenState(WelcomeScreenState.Welcome);
                return;
            }
            (async () => {
                route(`/${setName}/${puzzleNo}`);
            })();
        }

        const playButton = () => {
            loadProgress().then((asd) => {
                console.log(asd);
                route('/microban/1');
            });
        }

        // const user = useContext(GlobalContext).user;
        // if (!user) {
        //     console.error('No display name found in context, logging out.');
        //     useContext(GlobalContext).updateUser();
        //     route('/');
        //     return;
        // }

        // useEffect(() => {
        //     (async () => {
        //         const res = await getActivities();
        //         setActivity(res);
        //     })();
        // }, []);

        const displayName = 'Guest';
        const greeting = determineGreeting();

        return (
            <VisibleAfterTimeout>
                <FullScreenParticlesComponent/>
                <ImageWithTextComponent text={`${greeting},\n${displayName}`}/>
                {welcomeScreenState === WelcomeScreenState.Welcome && (
                    <MenuButtons>
                        <MenuButton onClick={() => setWelcomeScreenState(WelcomeScreenState.LocalGame)}>Play</MenuButton>
                        <MenuButton
                            onClick={() => setWelcomeScreenState(WelcomeScreenState.OnlineGame)}>Ladder</MenuButton>
                        <MenuButton
                            onClick={() => setWelcomeScreenState(WelcomeScreenState.Credits)}>Credits</MenuButton>
                    </MenuButtons>)}
                {welcomeScreenState === WelcomeScreenState.LocalGame && (
                    <LocalPuzzleSetSelection onSelect={selectDifficulty}/>)}
                {welcomeScreenState === WelcomeScreenState.OnlineGame && (
                    <LocalPuzzleSetSelection onSelect={selectDifficulty}/>)}
                {welcomeScreenState === WelcomeScreenState.Credits && (
                    <CreditsComponent onClick={() => setWelcomeScreenState(WelcomeScreenState.Welcome)}/>)}
                <FooterText>{footerText}</FooterText>
            </VisibleAfterTimeout>
        );
    }
}

/*
                    {activity.map((act, index) => (
                        <AnimatedGreeting key={index} className={index === currentActivityIndex ? 'active' : ''}>
                            {act.subject}{' '} finished{' '}
                            <Link href={`/puzzle/${act.puzzleId}`}
                                  style={{color: 'red', textDecoration: 'none'}}>{act.puzzleId}</Link>
                            {' '}in {TimeUtils.formatDelta(act.timeToSolve, true)} and {act.steps} steps
                        </AnimatedGreeting>
                    ))}
 */
