import {Capacitor} from '@capacitor/core';
import {createAuthApiClient} from '@thomaszeman/web-common/auth/index.js';
import {PuzzleToSolve} from './sokoban-scene-lifecycle.js';

export function getApiBaseUrl(): string {
    if (Capacitor.isNativePlatform()) {
        return 'https://api.nocap.gg';
    }
    const hostname = window.location.hostname;
    const protocol = window.location.protocol;
    if (['localhost', '127.0.0.1', '::1', 'nocap.gg.net'].includes(hostname) || hostname.startsWith('10.0.0.')) {
        return `http://${hostname}:1234/api`;
    } else {
        return `${protocol}//api.${hostname}`;
    }
}

export const authApiClient = createAuthApiClient(getApiBaseUrl());

export async function fetchNewPuzzle(id: string): Promise<PuzzleToSolve | null> {
    let response = await fetch(`${getApiBaseUrl()}/puzzle/${id}`, {
        method: 'GET',
        ...await authApiClient.getHeaderWithContentType(),
    });
    if (response.status !== 200) {
        return null;
    }
    const data = await response.json();
    return data as PuzzleToSolve;
}

export async function fetchRandomPuzzle(difficulty?: string): Promise<PuzzleToSolve | null> {
    let response;
    if (difficulty === undefined) {
        response = await fetch(`${getApiBaseUrl()}/puzzle/random`, {
            method: 'GET',
            ...await authApiClient.getHeaderWithContentType(),
        });
    } else {
        response = await fetch(`${getApiBaseUrl()}/puzzle/random?difficulty=${difficulty}`, {
            method: 'GET',
            ...await authApiClient.getHeaderWithContentType(),
        });
    }
    if (response.status !== 200) {
        return null;
    }
    const data = await response.json();
    return data as PuzzleToSolve;
}

export async function reportPuzzleSolution(puzzleId: string, solution: string): Promise<void> {
    await fetch(`${getApiBaseUrl()}/puzzle/${puzzleId}/solution`, {
        method: 'POST',
        ...await authApiClient.getHeaderWithContentType(),
        body: JSON.stringify({solution}),
    });
}

export async function getActivities() {
    let response = await fetch(`${getApiBaseUrl()}/puzzle/activities`, {
        method: 'GET',
        ...await authApiClient.getHeaderWithContentType(),
    });
    return await response.json();
}

export async function getLeaderboard(puzzleId: string): Promise<{
    name: string,
    timeToSolve: number,
    steps: number
}[]> {
    let response = await fetch(`${getApiBaseUrl()}/puzzle/${puzzleId}/leaderboard`, {
        method: 'GET',
        ...await authApiClient.getHeaderWithContentType(),
    });
    return await response.json();
}

export async function getStats() {
    let response = await fetch(`${getApiBaseUrl()}/puzzle/my-stats`, {
        method: 'GET',
        ...await authApiClient.getHeaderWithContentType(),
    });
    return await response.json();
}
