import { assertNotNull } from '../../../shared/src/common.js';
import {EventBus} from '../../../shared/src/eventBus.js';
import { EventEmitter } from '../../../shared/src/eventEmitter.js';
import { Logger } from '../../../shared/src/Logger.js';
import {EVENT_WINDOW_RESIZE, EVENT_WINDOW_RESIZE_START, globalEventBus} from '../global-events.js';

export class Canvas {
    private _canvas: HTMLCanvasElement;
    private _ctx: CanvasRenderingContext2D;
    private _resizeEmitter: EventEmitter<{width: number, height: number}> = new EventEmitter();

    public constructor(canvas: HTMLCanvasElement, private eventBus: EventBus, private logger: Logger) {
        assertNotNull(logger, 'logger');
        this._canvas = canvas;
        this._ctx = this._canvas.getContext('2d', {alpha: false})!!;
        this.resizeStart = this.resizeStart.bind(this);
        this.resizeCanvas = this.resizeCanvas.bind(this);
        globalEventBus.on(EVENT_WINDOW_RESIZE_START, this.resizeStart);
        globalEventBus.onWithReplay(EVENT_WINDOW_RESIZE, this.resizeCanvas);
    }

    get resizeEvent(): EventEmitter<{width: number, height: number}> {
        return this._resizeEmitter;
    }

    get context(): CanvasRenderingContext2D {
        return this._ctx;
    }

    get element(): HTMLCanvasElement {
        return this._canvas;
    }

    private resizeStart(): void {
        this._canvas.width = 0;
        this._canvas.height = 0;
        this._canvas.style.width = '0px';
        this._canvas.style.height = '0px';
    }

    public addEventListener(type: string, listener: any, options?: boolean | AddEventListenerOptions): void {
        this._canvas.addEventListener(type, listener, options);
    }

    public removeEventListener(type: string, listener: any, options?: boolean | EventListenerOptions): void {
        this._canvas.removeEventListener(type, listener, options);
    }

    dispose(): void {
        globalEventBus.off(EVENT_WINDOW_RESIZE_START, this.resizeStart);
        globalEventBus.off(EVENT_WINDOW_RESIZE, this.resizeCanvas);
    }

    resizeCanvas() {
        const px = (v: number) => `${v}px`;
        const parentElement = ((this._canvas!.parentNode!) as HTMLDivElement);
        if (parentElement.classList.contains('hidden')) {
            throw new Error('parentElement of canvas is hidden');
        }
        let rect = ((this._canvas!.parentNode!) as HTMLDivElement).getBoundingClientRect();
        if (rect.width === 0 || rect.height === 0) {
            throw new Error('parentElement of canvas has width or height of 0');
        }
        //
        const deviceWidthDec = rect.width * devicePixelRatio;
        const deviceHeightDec = rect.height * devicePixelRatio;

        const deviceWidth = Math.round(deviceWidthDec);
        const deviceHeight = Math.round(deviceHeightDec);

        this._canvas.style.width = px(rect.width);
        this._canvas.style.height = px(rect.height);
        this._canvas.width = deviceWidth;
        this._canvas.height = deviceHeight;

        this.logger.debug(`devicePixelRatio: ${devicePixelRatio} rect.width: ${rect.width}, rect.height: ${rect.height} deviceWidth: ${deviceWidth}, deviceHeight: ${deviceHeight} rounding loss: ${deviceWidthDec - deviceWidth}, ${deviceHeightDec - deviceHeight}`);
        this._resizeEmitter.notify({ width: deviceWidth, height: deviceHeight });
    }

    public get viewportDimension(): { width: number, height: number } {
        return { width: this._canvas.width, height: this._canvas.height };
    }

    public get clientBoundingRect(): DOMRect {
        return this._canvas.getBoundingClientRect();
    }
}
