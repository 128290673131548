import {IDisposable} from '../../../shared/src/common.js';
import {EventBus} from '../../../shared/src/eventBus.js';
import {Logger, LoggerImpl} from '../../../shared/src/Logger.js';
import { AnimationManager } from '../graphics/animation-manager.js';
import { SceneLifecycle } from '../graphics/scene-lifecycle.js';
import { SpriteSheetLoader } from '../graphics/sprite.js';
import {fetchNewPuzzle} from './sokoban-api-client.js';
import {NewCommand} from './sokoban-game-command-dispatcher.js';
import {MoveCommand} from './sokoban-game-command.js';
import {
    LevelCompletedCommand,
    RestartCommand,
    SokobanGameScene,
    SokobanGameSceneConfig, SokobanSceneLifecycleInitialized,
    StartNextLevelCommand,
} from './sokoban-game-scene.js';
import { SokobanWorld } from './sokoban-world.js';

const convertSingleLinePuzzle = (map: string) => map.split('\n');

export interface PuzzleToSolve {
    uniqueId: string;
    name: string | null;
    description: string | null;
    puzzleData: string;
    difficulty: number | null;
    rating: number | null;
    firstShown: number;
    alreadySolved: boolean;
}

export interface LifecycleChangedPuzzle {
    type: 'changed-puzzle';
    puzzleId: string;
}

export interface LifecycleLevelCompleted {
    type: 'level-completed';
    steps: number;
    pushes: number;
    time: number;
    solution: string;
}

export type LifecycleEvent = LifecycleChangedPuzzle | LifecycleLevelCompleted;

export interface SokobanSceneLifecycleConfig {
    replay: boolean;
}

export class SolutionCommandDispatcher {
    constructor(private eventBus: EventBus, private playerEntityId: number, private solution: string, private logger: Logger) {
    }

    start() {
        const commands = this.solution.split('');
        let i = 0;
        const interval = setInterval(() => {
            if (i < commands.length) {
                const command = commands[i];
                //const player = this.ecs.entityManager.getEntityByName('player');
                if (this.playerEntityId) {
                    let dx = 0;
                    let dy = 0;
                    switch (command) {
                        case 'u':
                        case 'U':
                            dy = -1;
                            break;
                        case 'd':
                        case 'D':
                            dy = 1;
                            break;
                        case 'l':
                        case 'L':
                            dx = -1;
                            break;
                        case 'r':
                        case 'R':
                            dx = 1;
                            break;
                    }
                    const moveCommand = new MoveCommand(this.playerEntityId, dx, dy);
                    this.eventBus.emit(NewCommand, moveCommand);
                }
                i++;
            } else {
                clearInterval(interval);
            }
        }, 750);
    }

}


export class SokobanSceneLifecycle extends SceneLifecycle implements IDisposable {
    private scene!: SokobanGameScene | null;
    private currentPuzzle!: PuzzleToSolve;

    constructor(canvas: HTMLCanvasElement,
                private eventBus: EventBus,
              //  private puzzleFactory: () => Promise<PuzzleToSolve>,
                private sheetLoader: SpriteSheetLoader,
                private animationManager: AnimationManager,
                private config: SokobanSceneLifecycleConfig,
                logger: LoggerImpl) {
        super(canvas, logger);
        this.restart = this.restart.bind(this);
        this.nextPuzzle = this.nextPuzzle.bind(this);
        this.startNextLevelCommand = this.startNextLevelCommand.bind(this);
        this.eventBus.on(RestartCommand, this.restart);
        this.eventBus.on(StartNextLevelCommand, this.startNextLevelCommand);
        this.eventBus.emit(SokobanSceneLifecycleInitialized,{});
    }

    dispose(): void {
        if (this.scene) {
            this.scene.dispose();
        }
    }

    async nextPuzzle(puzzleToSolve: PuzzleToSolve) {
        if (this.scene) {
            this.scene.dispose();
            this.scene = null;
        }

        const nextPuzzle = convertSingleLinePuzzle(puzzleToSolve.puzzleData);
        const world = new SokobanWorld(nextPuzzle, 256, puzzleToSolve.uniqueId);
        this.logger.info(`Loaded map ${world.mapId}`);
  //      await this.onLifeCycleEvent({type: 'changed-puzzle', puzzleId: puzzleToSolve.uniqueId});

        const gameSceneConfig: SokobanGameSceneConfig = {
            world,
            stats: {firstShown: Math.floor(puzzleToSolve.firstShown / 1000), alreadySolved: puzzleToSolve.alreadySolved},
            replay: this.config.replay,
            sheetLoader: this.sheetLoader,
            animationManager: this.animationManager
        };

        this.currentPuzzle = puzzleToSolve;
        this.scene = new SokobanGameScene(this.eventBus, this.canvas, gameSceneConfig, this.logger);
        await this.scene.initialize();
        if (this.config.replay) {
            const playerId = this.scene.ecsInstance.entityManager.getEntityByName('player')!.id;
            const player = new SolutionCommandDispatcher(this.eventBus, playerId, 'dlllllUUUdddrruLdlUrrdrrruuLDrdLLLuRdrruulDlllldRR', this.logger);
            player.start();
        }
    }

    async restart() {
        await this.nextPuzzle(this.currentPuzzle);
    }

    private async startNextLevelCommand(evt: any) {
        const puzzle = evt as PuzzleToSolve;
        await this.nextPuzzle(puzzle);
    }
}
