import {SmartBuffer} from 'smart-buffer';

export class DiscreteGridComponent {
    cx: number;
    cy: number;
    tx: number;
    ty: number;
    elapsedTime: number;
    duration: number;

    constructor(cx: number, cy: number) {
        this.cx = cx;
        this.cy = cy;
        this.tx = cx;
        this.ty = cy;
        this.elapsedTime = 0;
        this.duration = 0;
    }

    setTarget(tx: number, ty: number, duration?: number) {
        this.tx = tx;
        this.ty = ty;
        this.elapsedTime = 0;
        this.duration = duration || 100;
    }

    isMoving() {
        return this.cx !== this.tx || this.cy !== this.ty;
    }

    isAboutToMove() {
        return (this.cx !== this.tx || this.cy !== this.ty) && this.elapsedTime === 0;
    }

    serialize(smartBuffer: SmartBuffer) {
        smartBuffer.writeFloatLE(this.cx);
        smartBuffer.writeFloatLE(this.cy);
        smartBuffer.writeFloatLE(this.tx);
        smartBuffer.writeFloatLE(this.ty);
        smartBuffer.writeFloatLE(this.elapsedTime);
        smartBuffer.writeFloatLE(this.duration);
    }

    static deserialize(smartBuffer: SmartBuffer) {
        const x = smartBuffer.readFloatLE();
        const y = smartBuffer.readFloatLE();
        const tx = smartBuffer.readFloatLE();
        const ty = smartBuffer.readFloatLE();
        const elapsedTime = smartBuffer.readFloatLE();
        const duration = smartBuffer.readFloatLE();
        const ne = new DiscreteGridComponent(x, y);
        ne.tx = tx;
        ne.ty = ty;
        ne.elapsedTime = elapsedTime;
        ne.duration = duration;
        return ne;
    }
}
