// Function to compute SHA-256 hash asynchronously
async function computeHash(message: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

// Secret key and offset (keep these values secret)
const sk = 'pou8AewiwDAevgrpIm'; // Secret key
const of = 1701; // Offset number

// Function to encode puzzle ID into a unique string
function encodePuzzleId(puzzleId: number): string {
    const encodedId = puzzleId + of;
    return btoa(encodedId.toString());
}

// Function to decode the unique string back to puzzle ID
function decodePuzzleId(code: string): number | null {
    try {
        const decodedString = atob(code);
        const encodedId = parseInt(decodedString, 10);
        const puzzleId = encodedId - of;
        return puzzleId;
    } catch (e) {
        // Decoding failed
        return null;
    }
}

interface PlayerProgress {
    sets: { [setName: string]: string }; // Mapping from set names to encoded puzzle codes
}

interface DecodedPlayerProgress {
    sets: { [setName: string]: number }; // Mapping from set names to decoded puzzle IDs
}

// Helper function to load progress data and validate the hash
async function loadProgressData(): Promise<PlayerProgress | null> {
    const storedString = localStorage.getItem('playerProgress');
    if (storedString) {
        const storedData = JSON.parse(storedString);
        const data = storedData.data;
        const hash = storedData.hash;
        const expectedHash = await computeHash(data + sk);
        if (hash === expectedHash) {
            const progress: PlayerProgress = JSON.parse(data);
            return progress;
        } else {
            // Hash mismatch, data may have been tampered with
            localStorage.removeItem('playerProgress');
            return null;
        }
    } else {
        return null;
    }
}

// Function to save progress for a single set
export async function saveProgress(setName: string, puzzleId: number) {
    // Load existing progress
    const existingProgress = await loadProgressData();

    // Initialize the progress object
    const progress: PlayerProgress = existingProgress || { sets: {} };

    // Get the existing puzzleId for the setName
    const existingPuzzleCode = progress.sets[setName];
    let existingPuzzleId = -1;
    if (existingPuzzleCode) {
        const decodedId = decodePuzzleId(existingPuzzleCode);
        existingPuzzleId = decodedId !== null ? decodedId : -1;
    }

    // Compare the new puzzleId with existingPuzzleId
    if (puzzleId > existingPuzzleId) {
        // Update the progress
        progress.sets[setName] = encodePuzzleId(puzzleId);

        // Save the updated progress
        const data = JSON.stringify(progress);
        const hash = await computeHash(data + sk);
        const storedData = {
            data: data,
            hash: hash,
        };
        localStorage.setItem('playerProgress', JSON.stringify(storedData));
    } else {
        // Do not update progress as new puzzleId is not greater
        // Optionally, log a message or handle accordingly
        console.log(
            `Progress not updated for ${setName}: new puzzleId (${puzzleId}) is not greater than existing puzzleId (${existingPuzzleId}).`
        );
    }
}

// Function to load and decode progress
export async function loadProgress(): Promise<DecodedPlayerProgress | null> {
    const progress = await loadProgressData();
    if (progress) {
        const decodedProgress: DecodedPlayerProgress = { sets: {} };

        // Decode each puzzleCode
        for (const setName in progress.sets) {
            const puzzleCode = progress.sets[setName];
            const puzzleId = decodePuzzleId(puzzleCode);
            if (puzzleId !== null) {
                // Valid puzzle ID, include it in the decoded progress
                decodedProgress.sets[setName] = puzzleId;
            } else {
                // Decoding failed, remove this set from progress
                console.log(`Failed to decode puzzleCode for set ${setName}`);
                // Optionally, you can remove the invalid set from stored progress
                // delete progress.sets[setName];
            }
        }

        return decodedProgress;
    } else {
        return null;
    }
}

// New function to get progress for a specific set
export async function getProgressForSet(setName: string): Promise<number | null> {
    const progress = await loadProgressData();
    if (progress) {
        const puzzleCode = progress.sets[setName];
        if (puzzleCode) {
            const puzzleId = decodePuzzleId(puzzleCode);
            if (puzzleId !== null) {
                // Valid puzzle ID for the specified set
                return puzzleId;
            } else {
                console.log(`Failed to decode puzzleCode for set ${setName}`);
                return null;
            }
        } else {
            // No progress for this set
            return null;
        }
    } else {
        // No valid progress data
        return null;
    }
}
