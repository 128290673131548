import {SmartBuffer} from 'smart-buffer';

export class TransformComponent {
    x: number;
    y: number;
    scale: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
        this.scale = 1;
    }

    setScale(scale: number) {
        this.scale = scale;
    }

    serialize(smartBuffer: SmartBuffer) {
        smartBuffer.writeFloatLE(this.x);
        smartBuffer.writeFloatLE(this.y);
        smartBuffer.writeFloatLE(this.scale);
    }

    static deserialize(smartBuffer: SmartBuffer) {
        const x = smartBuffer.readFloatLE();
        const y = smartBuffer.readFloatLE();
        const scale = smartBuffer.readFloatLE();
        const ne = new TransformComponent(x, y);
        ne.scale = scale;
        return ne;
    }
}
