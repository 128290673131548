import {Constructor} from './ecs.js';

export class ComponentRegistry {
    private componentTypes: Map<Constructor, { id: number }> = new Map();
    private typeIdToConstructor: Map<number, Constructor> = new Map();

    registerComponent(typeId: number, constructor: Constructor) {
        if (this.componentTypes.has(constructor) || this.typeIdToConstructor.has(typeId)) {
            throw new Error(`Component type ${constructor.name} or id ${typeId} is already registered`);
        }
        if (typeId > 250) {
            // See serialization
            throw new Error(`Component type ${constructor.name} id ${typeId} is too large`);
        }
        if (typeof constructor.deserialize !== 'function') {
            throw new Error(`Component type ${constructor.name} must implement deserialize`);
        }
        this.componentTypes.set(constructor, {id: typeId});
        this.typeIdToConstructor.set(typeId, constructor);
    }

    has(componentType: Constructor): boolean {
        return this.componentTypes.has(componentType);
    }

    getConstructorByTypeId(typeId: number): Constructor | undefined {
        return this.typeIdToConstructor.get(typeId);
    }

    getTypeIdByConstructor(constructor: Constructor): number | undefined {
        return this.componentTypes.get(constructor)?.id;
    }
}
