import { DiscreteGridComponent } from '../../../shared/src/ecs/discrete-grid-component.js';
import { Entity } from '../../../shared/src/ecs/entity.js';
import { EntityManager } from '../../../shared/src/ecs/entityManager.js';
import { SokobanBoxComponent } from './sokoban-box-component.js';
import { SokobanGameInfoComponent } from './sokoban-game-info-component.js';
import { SokobanImmovableComponent } from './sokoban-immovable-component.js';

export interface SokobanGameCommand {
    execute(entitySystem: EntityManager): void;
    undo(entitySystem: EntityManager): void;
}

export class MoveCommand implements SokobanGameCommand {
    constructor(private entityId: number, private dx: number, private dy: number) {
    }

    execute(entitySystem: EntityManager): void {
        const entity = entitySystem.getEntity(this.entityId);
        if (entity) {
            const playerGrid = entity.getComponent<DiscreteGridComponent>(DiscreteGridComponent)!;
            if (playerGrid.isMoving()) {
                return;
            }
            const tx = playerGrid.cx + this.dx;
            const ty = playerGrid.cy + this.dy;
            const allImmovables = entitySystem.getEntitiesWithAnyComponentsOf(SokobanImmovableComponent);
            const immovable = allImmovables.find((e) => {
                const immovablePos = e.getComponent<DiscreteGridComponent>(DiscreteGridComponent)!;
                return immovablePos.cx === tx && immovablePos.cy === ty;
            });
            if (immovable) {
                console.log('solid in front');
                return;
            }
            // box handling
            // find box
            const res = entitySystem.getEntitiesWithAnyComponentsOf(SokobanBoxComponent);
            const box = res.find((e) => {
                const boxPos = e.getComponent<DiscreteGridComponent>(DiscreteGridComponent)!;
                return boxPos.cx === tx && boxPos.cy === ty;
            });
            if (box) {
                // there is a box
                // solid behind the box?
                const immovable2 = allImmovables.find((e) => {
                    const immovablePos = e.getComponent<DiscreteGridComponent>(DiscreteGridComponent)!;
                    return immovablePos.cx === tx + + this.dx && immovablePos.cy === ty + this.dy;
                });
                if (immovable2) {
                    // solid behind the box
                    console.log('solid behind the box');
                    return;
                }
                // box behind the box?
                const box2 = res.find((e) => {
                    const boxPos = e.getComponent<DiscreteGridComponent>(DiscreteGridComponent)!;
                    return boxPos.cx === tx + this.dx && boxPos.cy === ty + this.dy;
                });
                if (box2) {
                    // box behind the box
                    console.log('box behind the box');
                    return;
                }
                const boxGrid = box.getComponent<DiscreteGridComponent>(DiscreteGridComponent)!;
                boxGrid.setTarget(tx + this.dx, ty + this.dy);
                entity.setComponent(DiscreteGridComponent, boxGrid);
            }
            playerGrid.setTarget(tx, ty);
            entity.setComponent(DiscreteGridComponent, playerGrid);

            const stats = entitySystem.getEntityByName('gameInfo')!.getComponent<SokobanGameInfoComponent>(SokobanGameInfoComponent)!;
            stats.incrementSteps();
            if (box) {
                stats.incrementPushes();
                stats.addSolutionStep(this.dx > 0 ? 'R' : this.dx < 0 ? 'L' : this.dy > 0 ? 'D' : 'U');
            } else {
                stats.addSolutionStep(this.dx > 0 ? 'r' : this.dx < 0 ? 'l' : this.dy > 0 ? 'd' : 'u');
            }

        } else {
            console.error(`Entity with ID ${this.entityId} not found`);
        }
    }

    undo(entitySystem: EntityManager): void {
        const entity = entitySystem.getEntity(this.entityId);
        if (entity) {
           // this.movePlayer(entity, -this.dx, -this.dy);
        } else {
            console.error(`Entity with ID ${this.entityId} not found`);
        }
    }
}
