import { SmartBuffer } from 'smart-buffer';

export class SokobanTargetComponent {

    constructor() {
    }

    serialize(smartBuffer: SmartBuffer) {
    }

    static deserialize(smartBuffer: SmartBuffer) {
        const ne = new SokobanTargetComponent();
        return ne;
    }
}

