import {styled} from 'goober';
import {forwardRef} from 'preact/compat';

export const MenuContainer = styled('div')`
    position: absolute;
    top: 50%;
    display: flex;
    max-width: 400px;
    width: 80%;
    flex-direction: column;
    justify-content: center;
    left: 50%;
    transform: translate(-50%, 0%);
`;

export const ButtonBaseStyle = `
    padding: 10px 20px;
    background: #fbecd9;
    color: #654217;
    border: 3px solid #c18960;
    cursor: pointer;
    font-family: 'Capture_it', serif;
    font-size: 1.5rem;
    transition: background-color 0.3s, color 0.3s;
`;

export const SetButton = styled('button', forwardRef)`
    ${ButtonBaseStyle};
    flex: 1;
    margin: 10px 10px;
`;

export const SetProgressLabel = styled('div')`
    ${ButtonBaseStyle};
    padding: 10px 10px;
    margin: 10px 10px;
    cursor: pointer;
`;
