export type Subscriber<T> = (value: T) => void;

export class EventEmitter<T = void> {
  private subscribers: Subscriber<T>[] = [];

  public subscribe(subscriber: Subscriber<T>): void {
    this.subscribers.push(subscriber);
    if (this.subscribers.length > 5) {
      // eslint-disable-next-line no-console
      console.error(`Warning, EventEmitter has more than 5 subscribers ${JSON.stringify(this.subscribers)}`);
    }
  }

  public unsubscribe(subscriber: Subscriber<T>): void {
    this.subscribers = this.subscribers.filter((s) => s !== subscriber);
  }

  public notify(value:T = (null as T)): void {
    for (const subscriber of this.subscribers) {
      const result = subscriber(value) as unknown;
      if (result instanceof Promise) {
        // eslint-disable-next-line no-loop-func
        result.catch((error) => {
          // eslint-disable-next-line no-console
          console.error(`Error in subscriber: ${error}`);
        });
      }
    }
  }
}

