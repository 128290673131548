import { SmartBuffer } from 'smart-buffer';

export enum KeyState {
    Up = 0,
    Down = 1,
    Held = 2,
    Released = 3
}

export enum MouseButton {
    Left = 0,
    Right = 1,
    Middle = 2
}

export class InputComponent {
    public keyStates: Map<string, KeyState>;
    public mouseButtonStates: Map<MouseButton, KeyState>;
    public mousePosition: { x: number; y: number };
    public wasClicked: boolean;
    public clickPosition: { x: number; y: number };

    constructor() {
        this.keyStates = new Map<string, KeyState>();
        this.mouseButtonStates = new Map<MouseButton, KeyState>();
        this.mousePosition = { x: 0, y: 0 };
        this.wasClicked = false;
        this.clickPosition = { x: 0, y: 0 };
    }

    public setKeyState(key: string, state: KeyState): void {
        this.keyStates.set(key, state);
    }

    public getKeyState(key: string): KeyState {
        return this.keyStates.get(key) || KeyState.Up;
    }

    public setMouseButtonState(button: MouseButton, state: KeyState): void {
        this.mouseButtonStates.set(button, state);
    }

    public getMouseButtonState(button: MouseButton): KeyState {
        return this.mouseButtonStates.get(button) || KeyState.Up;
    }

    public updateMousePosition(x: number, y: number): void {
        this.mousePosition.x = x;
        this.mousePosition.y = y;
    }

    public setClickState(clicked: boolean, x: number, y: number): void {
        this.wasClicked = clicked;
        if (clicked) {
            this.clickPosition.x = x;
            this.clickPosition.y = y;
        }
    }

    static deserialize(smartBuffer: SmartBuffer): InputComponent {
        throw new Error('Method not implemented.');
    }
}
