import {styled} from 'goober';
import {h} from 'preact';
import {TimeUtils} from '../../shared/src/time-utils.js';
import {ButtonContainer, DialogBox, RetroButton, StatText, HeaderText} from './rating-dialog.js';

const DialogContentWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;
`;

export const PuzzleCompletedDialog = ({
                                          playerName,
                                          steps,
                                          pushes,
                                          time,
                                          onNext,
                                      }: {
    playerName: string;
    steps: number;
    pushes: number;
    time: number;
    onNext: () => void;
}) => {
    return (
        <DialogBox>
            {playerName ? <HeaderText>Congratulations, {playerName}!</HeaderText> : <HeaderText>Congratulations!</HeaderText>}
            <DialogContentWrapper>
                <StatText>Steps: {steps} <span className="emoji">👩‍🔧</span></StatText>
                <StatText>Pushes: {pushes} <span className="emoji">💪</span></StatText>
                <StatText>Time: {TimeUtils.formatDelta(time)} <span class="emoji">⏱️</span></StatText>
            </DialogContentWrapper>
            <RetroButton onClick={onNext}>Next</RetroButton>
        </DialogBox>
    );
};
