import { IDisposable } from '../../../shared/src/common.js';
import { Ecs } from '../../../shared/src/ecs/ecs.js';
import { System } from '../../../shared/src/ecs/system.js';
import { TransformComponent } from '../../../shared/src/ecs/transform-component.js';
import { Logger } from '../../../shared/src/Logger.js';
import { CameraComponent } from '../graphics/camera-component.js';
import { Canvas } from '../graphics/canvas.js';
import { RenderInstance } from '../graphics/render-instance.js';
import { Viewport } from '../graphics/viewport.js';
import { SokobanWorld } from './sokoban-world.js';

export class SokobanCameraSystem extends System implements IDisposable {

    constructor(private ecs: Ecs, private sokobanWorld: SokobanWorld, private canvas: Canvas, logger: Logger) {
        super(logger);
        this.canvasResized = this.canvasResized.bind(this);
        this.canvas.resizeEvent.subscribe(this.canvasResized);
        const entity5 = this.ecs.entityManager.createEntity();
        entity5.name = 'camera';
        entity5.setComponent(TransformComponent, new TransformComponent(0,0));
        entity5.setComponent(CameraComponent, new CameraComponent(new Viewport(0,0, canvas.viewportDimension.width, canvas.viewportDimension.height)));
        this.logger.debug(`SokobanCameraSystem created ${canvas.viewportDimension.width}x${canvas.viewportDimension.height}`);
    }

    dispose(): void {
        this.canvas.resizeEvent.unsubscribe(this.canvasResized);
    }

    canvasResized() {
        const canvas = this.canvas;
        this.logger.debug(`SokobanCameraSystem resized ${canvas.viewportDimension.width}x${canvas.viewportDimension.height}`);
        console.log('resize');
        const camera = this.ecs.entityManager.getEntityByName('camera')!.getComponent<CameraComponent>(CameraComponent)!;
        camera.viewport = new Viewport(0,0, canvas.viewportDimension.width, canvas.viewportDimension.height);
        this.ecs.entityManager.getEntityByName('camera')!.markComponentDirty(CameraComponent);
    }

    public update(deltaTime: number) {
        super.update(deltaTime);
        const cameraEntity = this.ecs.entityManager.getEntityByName('camera')!;

        const camera = cameraEntity.getComponent< CameraComponent>(CameraComponent)!;
        if (camera.viewport.width < camera.viewport.height) {
            camera.zoom = camera.viewport.width / (this.sokobanWorld.worldDimensions.width);
        } else {
            camera.zoom = camera.viewport.height / (this.sokobanWorld.worldDimensions.height);
        }

        const pos = cameraEntity.getComponent<TransformComponent>(TransformComponent)!;
        pos.x = camera.viewport.x + camera.viewport.width / 2;
        pos.y = camera.viewport.y + camera.viewport.height / 2;

        camera.matrix = new RenderInstance();
        camera.matrix.setPosition(pos.x, pos.y);
        camera.matrix.setScale(camera.zoom);
    }
}
