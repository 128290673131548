import {assertDefined} from '../../../shared/src/common.js';
import {getBundledAssetUrl, loadBundledJson} from '../bundle-helper.js';
import {Rectangle2D} from './maths.js';

interface Frame {
    frame: { x: number, y: number, w: number, h: number };
    rotated: boolean;
    trimmed: boolean;
    spriteSourceSize: { x: number, y: number, w: number, h: number };
    sourceSize: { w: number, h: number };
    customScale?: number;
}

interface SpriteSheetData {
    frames: { [key: string]: Frame };
    meta: {
        app: string;
        version: string;
        image: string;
        format: string;
        size: { w: number, h: number };
        scale: string;
    };
}

export let spriteSheetDebug = false;

export class SpriteSheet {
    private spriteSheetData: SpriteSheetData | null = null;
    private spriteSheetImage: ImageBitmap | null = null;
    private frameIndexMap: Map<number, Frame> = new Map(); // Maps index to Frame
    private nameToIndexMap: Map<string, number> = new Map(); // Maps name to index

    constructor(private jsonFilename: string) {
        assertDefined(jsonFilename, 'jsonFilename');
    }

    // Load the sprite sheet description file and image
    async load(): Promise<void> {
        await this.loadJsonData();
        await this.loadImage();
    }

    // Load and parse the JSON data
    private async loadJsonData(): Promise<void> {
        const data: SpriteSheetData = await loadBundledJson(this.jsonFilename);
        this.spriteSheetData = data;

        // Populate frameIndexMap and nameToIndexMap
        let index = 0;
        for (const [name, frame] of Object.entries(data.frames)) {
            this.frameIndexMap.set(index, frame);
            this.nameToIndexMap.set(name, index);
            index++;
        }
    }

    // Load the sprite sheet image
    private async loadImage(): Promise<void> {
        if (this.spriteSheetData) {
            const imageUrl = getBundledAssetUrl(this.spriteSheetData.meta.image);
            const res = await fetch(imageUrl);
            if (!res.ok) {
                throw new Error(`Failed to load sprite sheet: ${res.statusText}`);
            }
            // Check if the response is of a valid image MIME type
            const contentType = res.headers.get('Content-Type');
            if (!contentType || !contentType.startsWith('image/')) {
                throw new Error(`Url: ${imageUrl} has an invalid image format: ${contentType}`);
            }
            const image = await res.blob();
            this.spriteSheetImage = await createImageBitmap(image);
        } else {
            throw new Error("SpriteSheet data not loaded.");
        }
    }

    // Get a handle (index) for a sprite by its name
    getSpriteIndex(name: string): number | undefined {
        return this.nameToIndexMap.get(name);
    }

    // Render a sprite to a canvas context
    renderSprite(context: CanvasRenderingContext2D, index: number): void {
        const frame = this.frameIndexMap.get(index);
        if (!frame) {
            console.log(`Frame not found for index: ${index}`);
            // draw a red rectangle to indicate missing frame
            context.fillStyle = 'red';
            context.fillRect(0, 0, 100, 100);
            return;
        }

        const scale = frame.customScale || 1;
        const targetSizeWidth = frame.frame.w * scale;
        const targetSizeHeight = frame.frame.h * scale;

        const x = -targetSizeWidth / 2;
        const y = -targetSizeHeight / 2;

        context.drawImage(
            this.spriteSheetImage!,
            frame.frame.x, frame.frame.y, frame.frame.w, frame.frame.h, // Source position and size on sprite sheet
            x, y, targetSizeWidth, targetSizeHeight,                          // Destination position and size on canvas
        );
    }

    getFrameRect(index: number): { w: number, h: number } {
        const frame = this.frameIndexMap.get(index);
        if (!frame) {
            throw new Error(`Frame not found for index: ${index}`);
        }
        return {w: frame.frame.w, h: frame.frame.h};
    }

    renderSpriteInRect(context: CanvasRenderingContext2D, index: number, rect: Rectangle2D): void {
        const frame = this.frameIndexMap.get(index);
        if (!frame) {
            throw new Error(`Frame not found for index: ${index}`);
        }

        const {x, y, w, h} = rect;

        context.drawImage(
            this.spriteSheetImage!,
            frame.frame.x, frame.frame.y, frame.frame.w, frame.frame.h, // Source position and size on sprite sheet
            x, y, w, h,                          // Destination position and size on canvas
        );

        if (spriteSheetDebug) {
            context.strokeStyle = 'red';
            context.strokeRect(x, y, w, h);
        }
    }
}
