import { SmartBuffer } from 'smart-buffer';

export class SokobanBoxComponent {
    isInRightSpot: boolean;

    constructor(isInRightSpot: boolean) {
        this.isInRightSpot = isInRightSpot;
    }

    serialize(smartBuffer: SmartBuffer) {
        smartBuffer.writeUInt8(this.isInRightSpot ? 1 : 0);
    }

    static deserialize(smartBuffer: SmartBuffer) {
        const ll = smartBuffer.readUInt8() === 1;
        const ne = new SokobanBoxComponent(ll);
        return ne;
    }
}

