import { assertDefined } from '../../../shared/src/common.js';
import { SpriteSheet } from './sprite-sheet.js';

interface AnimationConfig {
    [key: string]: string[]; // Key is the state name, value is an array of sprite names
}

interface EntityConfig {
    animation: AnimationConfig;
    sheetName: string;
}

export interface ThemeConfig {
    [key: string]: GameConfig;
}

export interface GameConfig {
    [key: string]: EntityConfig;
}

export interface Animation {
    [key: string]: number[]; // Key is the state name, value is an array of sprite indices
}

export interface SpriteEntity {
    spriteSheet: SpriteSheet;
    animation: Animation;
}

export class AnimationManager {
    private resolvedItems: Map<string, SpriteEntity> = new Map();

    constructor(private config: GameConfig, private spriteSheetLoader: (name: string) => Promise<SpriteSheet>) {}

    async initialize(): Promise<void> {
        for (const [itemName, entityConfig] of Object.entries(this.config)) {
            // Load the SpriteSheet instance if not already loaded
            assertDefined(entityConfig.sheetName, `sheetName missing for '${itemName}' in ${JSON.stringify(this.config)}`);
            let spriteSheet = await this.spriteSheetLoader(entityConfig.sheetName);
            // Resolve animations
            const resolvedAnimation: Animation = {};
            for (const [state, frames] of Object.entries(entityConfig.animation)) {
                resolvedAnimation[state] = frames.map(frameName => {
                    const index = spriteSheet!.getSpriteIndex(frameName);
                    if (index === undefined) {
                        throw new Error(`Frame ${frameName} not found in sprite sheet ${entityConfig.sheetName}`);
                    }
                    return index;
                });
            }

            // Store the resolved entity
            this.resolvedItems.set(itemName, {
                spriteSheet: spriteSheet,
                animation: resolvedAnimation,
            });
        }
    }

    getEntityAnimation(itemName: string): SpriteEntity {
        return assertDefined(this.resolvedItems.get(itemName), `Entity ${itemName} not found`);
    }
}
