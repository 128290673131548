export class TimeUtils {
    /**
     * Get the current timestamp in seconds.
     */
    static getCurrentTimestamp(): number {
        return Math.floor(Date.now() / 1000);
    }

    /**
     * Convert a delta in seconds into a formatted string 'mm:ss'.
     * @param deltaSeconds The number of seconds elapsed.
     * @param showUnits Whether to show the units 'm' and 's'.
     */
    static formatDelta(deltaSeconds: number, showUnits: boolean = false): string {
        const minutes = Math.floor(deltaSeconds / 60);
        const seconds = deltaSeconds % 60;

        // Pad the minutes and seconds with zeros if they are less than 10
        const paddedMinutes = minutes.toString().padStart(2, '0');
        const paddedSeconds = seconds.toString().padStart(2, '0');

        if (showUnits) {
            return `${paddedMinutes}m:${paddedSeconds}s`;
        } else {
            return `${paddedMinutes}:${paddedSeconds}`;
        }
    }

    static formatDeltaSeconds(deltaSeconds: number): string {
        const paddedSeconds = deltaSeconds.toString().padStart(2, '0');
        return `${paddedSeconds}`;
    }
}
