import { CameraComponent } from './camera-component.js';
import { Canvas } from './canvas.js';
import { RenderInstance } from './render-instance.js';
import { assertNotNull, IDisposable } from '../../../shared/src/common.js';
import { Logger } from '../../../shared/src/Logger.js';

export interface IRenderEngine extends IDisposable {
    registerGameObject(gameObject: RenderInstance): void;
    unregisterGameObject(gameObject: RenderInstance): void;
    beginFrame(): void;
    render(camera: CameraComponent): void;
}

export class RenderEngine implements IRenderEngine {
    private _canvas: Canvas;
    private _ctx: CanvasRenderingContext2D;

    public constructor(canvas: Canvas, private logger: Logger) {
        assertNotNull(logger, 'logger');
        this._canvas = canvas;
        this._ctx = this._canvas.context;
    }

    dispose(): void {
    }

    private _gameObjects: RenderInstance[] = [];

    public registerGameObject(gameObject: RenderInstance): void {
        this._gameObjects.push(gameObject);
    }

    public unregisterGameObject(gameObject: RenderInstance): void {
        const index = this._gameObjects.indexOf(gameObject);
        if (index >= 0) {
            this._gameObjects.splice(index, 1);
        }
    }

    public scheduleRender(): void {

    }

    public beginFrame(): void {
        const ctx = this._ctx;

        ctx.clearRect(0, 0, this._canvas.viewportDimension.width, this._canvas.viewportDimension.height); // transparent black per default

        // Reset transformations
        if (ctx.resetTransform) {
            ctx.resetTransform();  // Modern browsers
        } else {
            ctx.setTransform(1, 0, 0, 1, 0, 0);  // Fallback for older browsers
        }
    }

    public render(camera: CameraComponent): void {
        const context = this._ctx;
        context.beginPath();
        context.rect(camera.viewport.x, camera.viewport.y, camera.viewport.width, camera.viewport.height);
        context.clip();
        context.fillStyle = '#000000';
        context.fillRect(0, 0, this._canvas.viewportDimension.width, this._canvas.viewportDimension.height);
        //return;
        this._ctx.resetTransform();
        camera.matrix.render(this._ctx);
        const s = this._ctx.getTransform();
        for (const gameObject of this._gameObjects) {
            this._ctx.setTransform(s);
            gameObject.render(this._ctx);
        }
    }
}

// https://stackoverflow.com/questions/47696956/display-pixel-perfect-canvas-on-all-devices
// https://stackoverflow.com/questions/14488849/higher-dpi-graphics-with-html5-canvas
// https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio?retiredLocale=de
// https://webglfundamentals.org/webgl/lessons/webgl-resizing-the-canvas.html


