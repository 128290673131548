import { render } from 'preact';
import { assertDefined } from '../../../shared/src/common.js';
import { RenderInstance } from './render-instance.js';
import { SpriteSheet } from './sprite-sheet.js';

export interface Animation {
    [key: string]: number[];  // Animation name as key, array of frame indices as value
}

export interface Item {
    animation: Animation;  // Each item has an animation object
}

export interface Items {
    [key: string]: Item;  // The key represents the item name (e.g., 'player', 'enemy')
}

export interface SpriteSheetDescription {
    url: string;          // URL to the sprite sheet
    spriteWidth: number;  // Width of each sprite in the sheet
    spriteHeight: number; // Height of each sprite in the sheet
    items: Items;         // Collection of items
}

export class SpriteSheetLoader {
    private cache: Map<string, SpriteSheet> = new Map<string, SpriteSheet>();

    constructor() {
    }

    public async getSpriteSheet(name: string): Promise<SpriteSheet> {
        assertDefined(name, 'name');
        if (this.cache.has(name)) {
            return this.cache.get(name)!;
        }
        const sheet = new SpriteSheet(name);
        await sheet.load();
        this.cache.set(name, sheet);
        return sheet;
    }
}

export class Sprite extends RenderInstance {
    private frame: number = 0;
    private lastFrameTime: number = 0;
    private _frames: number[] = [];

    set frames(value: number[]) {
        this._frames = value;
        this.frame = 0;
        this.lastFrameTime = 0;
    }

    get frames(): number[] {
        return this._frames;
    }

    constructor(private sheet: SpriteSheet, frames: number[]) {
        super();
        this.frames = frames;
    }

    public incrementFrame(delta: number) {
        this.lastFrameTime += delta;
        if (this.lastFrameTime < (250/8)) {
            return;
        }
        this.lastFrameTime = 0;
        this.frame = (this.frame + 1) % this.frames.length;
    }

    public render(ctx: CanvasRenderingContext2D): void {
        super.render(ctx);
        this.sheet.renderSprite(ctx, this.frames[this.frame]);
    }
}
