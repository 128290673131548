import { AnimationManager } from './animation-manager.js';
import { Canvas } from './canvas.js';

export class UiContext {
    private _animationManager: AnimationManager;
    private _canvas: Canvas;
    private _scaleFactor: number = 1;
    private _standardFontSize: number = 24;

    public get animationManager(): AnimationManager {
        return this._animationManager;
    }

    public get canvasContext(): CanvasRenderingContext2D {
        return this._canvas.context;
    }

    public get canvas(): Canvas {
        return this._canvas;
    }

    public get viewportDimension(): { width: number, height: number } {
        return this._canvas.viewportDimension;
    }

    public get scaleFactor(): number {
        return this._scaleFactor;
    }

    public set scaleFactor(value: number) {
        this._scaleFactor = value;
    }

    public get standardFontSize(): number {
        const temp = (this._standardFontSize * this._scaleFactor * 0.66);
        const floor = Math.floor(temp);
        return floor;
    }

    public set standardFontSize(value: number) {
        this._standardFontSize = value;
    }

    public constructor(canvas: Canvas, animationManager: AnimationManager) {
        this._animationManager = animationManager;
        this._canvas = canvas;
    }
}

export enum Anchor {
    None,
    TopLeft,
    TopRight,
    BottomLeft,
    BottomRight,
    Center
}
