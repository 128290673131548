import { h } from 'preact';

// @ts-ignore
const withAuthWall = (WrappedComponent) => {
    // @ts-ignore
    return (props) => {
        // const context = useContext(GlobalContext);
        // const [externalLibrariesLoaded, setExternalLibrariesLoaded] = useState(areWeLoaded());
        //
        // useEffect(() => {
        //     registerLoadingFinished(() => {
        //         setExternalLibrariesLoaded(true);
        //     });
        // }, []);
        //
        // const setUserInContext = async (): Promise<boolean> => {
        //     try {
        //         const accessToken = await authApiClient.getCurrentOrNewAccessToken();
        //         if (!accessToken) {
        //             setUserInContextToNull();
        //             return false;
        //         }
        //         const idToken = await authApiClient.getCurrentOrNewIdToken();
        //         await setUserFromToken(accessToken, idToken, context.setUser);
        //         return true;
        //     }
        //     catch(e) {
        //         console.error(e);
        //     }
        //     return false;
        // }
        //
        // const setUserInContextToNull = () => {
        //     context.setUser(null);
        // }
        //
        // useEffect(() => {
        //     if (!authApiClient.hasValidAccessToken()) {
        //         console.log('No valid access token, getting status');
        //         authApiClient.getAuthStatus().then((isAuthenticated) => {
        //             console.log('Is authenticated:', isAuthenticated);
        //             if (!isAuthenticated) {
        //                 setUserInContextToNull();
        //                 route('/login');
        //             } else {
        //                 setUserInContext().then((success) => {
        //                     if (!success) {
        //                         route('/login');
        //                     }
        //                 });
        //             }
        //         });
        //     } else {
        //         if (context.user === null) {
        //             console.log('Setting user in context');
        //             setUserInContext().catch((e) => {
        //                 console.error(e);
        //             });
        //         }
        //     }
        //
        // }, []); // Dependencies array is empty, so this runs only once after the component mounts
        //
        // if (!externalLibrariesLoaded && requiresExternal) {
        //     return <div>Loading</div>;
        // }
        //return <div style={{color: '#ffffff'}}>Loading</div>;
        // const context = useContext(GlobalContext);
        // if (!context.user) {
        //     return <PlayerNameDialog/>;
        // }
        return <WrappedComponent {...props} />;
    };
};

export default withAuthWall;
