import { InputSystemEvent } from './input-system.js';
import { Anchor, UiContext } from './ui-context.js';

type PositionType = 'static' | 'relative' | 'absolute' | 'fixed';

export abstract class UiElement {

    protected _context: UiContext;
    protected parent: UiElement | null = null;
    protected _anchor: Anchor = Anchor.None;
    protected _x: number;
    protected _y: number;
    protected _width: string;
    protected _height: string;
    protected _positionType: PositionType = 'static';
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
    protected _dirty: boolean = true;
    calculatedWidth: number = 0;
    calculatedHeight: number = 0;

    constructor(
        context: UiContext,
    ) {
        this._context = context;
        this._x = 0;
        this._y = 0;
        this._width = 'auto';
        this._height = 'auto';
    }

    get positionType(): PositionType {
        return this._positionType;
    }

    set positionType(value: PositionType) {
        this._positionType = value;
    }

    // Abstract render method to be implemented by subclasses
    abstract render(): void;

    public layout(): void {
    }

    // Set parent for this element
    public setParent(parent: UiElement | null): void {
        this.parent = parent;
    }

    protected dimensionsChanged(): void {
    }

    protected positionChanged(): void {

    }

    public childDimensionsChanged(child: UiElement) {
    }

    public get width(): string {
        return this._width;
    }

    public set width(width: number | string) {
        // if string must end with px or auto
        if (typeof width === 'string') {
            if (!width.endsWith('px') && width !== 'auto') {
                throw new Error(`Invalid width: ${width}`);
            }
        }

        const widthStr = typeof width === 'number' ? `${width}px` : width;
        if (this._width === widthStr) {
            return;
        }
        this._width = widthStr;
        this.dimensionsChanged();
    }

    public get height(): string {
        return this._height;
    }

    public set height(height: number | string) {
        // if string must end with px or auto
        if (typeof height === 'string') {
            if (!height.endsWith('px') && height !== 'auto') {
                throw new Error(`Invalid width: ${height}`);
            }
        }

        const heightStr = typeof height === 'number' ? `${height}px` : height;
        if (this._height === heightStr) {
            return;
        }
        this._height = heightStr;
        this.dimensionsChanged();
    }

    public get position(): { x: number, y: number } {
        return { x: this._x, y: this._y };
    }

    public set position(position: { x: number, y: number }) {
        if (this._x === position.x && this._y === position.y) {
            return;
        }
        this._x = position.x;
        this._y = position.y;
        this.positionChanged();
    }

    public invalidate(): void {
        this._dirty = true;
    }

    handleInputEvent(event: InputSystemEvent): boolean {
        return false;
    }
}
