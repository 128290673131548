import { SmartBuffer } from 'smart-buffer';
import { RenderInstance } from './render-instance.js';
import { Viewport } from './viewport.js';

export class CameraComponent {

    public zoom: number = 1;
    public color: string = '#000000';
    public targetEntity: number = 0;
    public matrix: RenderInstance = new RenderInstance();

    constructor(public viewport: Viewport) {
    }

    static deserialize(smartBuffer: SmartBuffer) {
    }
}

