const mapNameToBundledName: Record<string, URL> = {
    'abstract.png': new URL('../assets/abstract.png', import.meta.url),
    'i1.png': new URL('../assets/i1.png', import.meta.url),
    'i2.png': new URL('../assets/i2.png', import.meta.url),
}

const mapJsonNameToBundledName: Record<string, Promise<unknown>> = {
    'root.json': import('../assets/root.json'),
    'abstract.json': import('../assets/abstract.json'),
    'i1.json': import('../assets/i1.json'),
    'i2.json': import('../assets/i2.json'),
    'microban.json': import('../assets/microban.json'),
}

export function getBundledAssetUrl(name: string): string {
    const asset = mapNameToBundledName[name];
    if (!asset) {
        throw new Error(`Asset not found: ${name}`);
    }
    return asset.href;
}

export async function loadBundledJson<T>(name: string): Promise<T> {
    const bundleName = mapJsonNameToBundledName[name];
    if (!bundleName) {
        throw new Error(`Bundle not found: ${name}`);
    }
    return (await bundleName) as T;
}
