export interface Point2D {
    x: number;
    y: number;
}

export interface Rectangle2D {
    x: number;
    y: number;
    w: number;
    h: number;
}

export function scaleRectToHeight(rect: Rectangle2D, height: number): Rectangle2D {
    const aspectRatio = rect.w / rect.h;
    return {
        x: rect.x,
        y: rect.y,
        w: height * aspectRatio,
        h: height,
    };
}

export function scaleRectToWidth(rect: Rectangle2D, width: number): Rectangle2D {
    const aspectRatio = rect.w / rect.h;
    return {
        x: rect.x,
        y: rect.y,
        w: width,
        h: width / aspectRatio,
    };
}

export function moveRectangle(rectangle: Rectangle2D, dx: number, dy: number): Rectangle2D {
    return {
        x: rectangle.x + dx,
        y: rectangle.y + dy,
        w: rectangle.w,
        h: rectangle.h,
    };
}

export function isPointInsideRectangle(point: Point2D, rectangle: Rectangle2D): boolean {
    return (
        point.x >= rectangle.x &&
        point.x <= rectangle.x + rectangle.w &&
        point.y >= rectangle.y &&
        point.y <= rectangle.y + rectangle.h
    );
}

export function inverseMatrix(matrix: number[]): number[] {
    let a = matrix[0], b = matrix[1], c = matrix[2], d = matrix[3], e = matrix[4], f = matrix[5];

    let determinant = a * d - b * c;

    return [
        d / determinant,
        -b / determinant,
        -c / determinant,
        a / determinant,
        (c * f - d * e) / determinant,
        (b * e - a * f) / determinant,
    ];
}

export function screenToWorld(point: [number, number], cameraMatrix: number[]): { x: number, y: number } {
    let inverse = inverseMatrix(cameraMatrix);

    let x = point[0] * inverse[0] + point[1] * inverse[2] + inverse[4];
    let y = point[0] * inverse[1] + point[1] * inverse[3] + inverse[5];

    return { x, y };
}

interface Matrix2D {
    a: number;
    b: number;
    c: number;
    d: number;
    e: number;
    f: number;
}
