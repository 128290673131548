import {ComponentManager} from './componentManager.js';
import {Entity} from './entity.js';
import {EventEmitter} from '../eventEmitter.js';
import {Constructor} from './ecs.js';
import {ComponentRegistry} from './componentRegistry.js';

export class EntityManager {
    public componentAdded: EventEmitter<{entityId: number, componentType: Constructor}> = new EventEmitter();
    public componentRemoved: EventEmitter<{entityId: number, componentType: Constructor}> = new EventEmitter();

    private readonly componentManager: ComponentManager;
    private lastId = 0;
    private entities: Entity[] = [];
    private entitiesByComponent: Map<Constructor, Entity[]> = new Map();
    private entityNames: Map<string, Entity> = new Map();

    constructor(componentRegistry: ComponentRegistry) {
        this.componentManager = new ComponentManager(componentRegistry);
    }

    getComponentManager(): ComponentManager {
        return this.componentManager;
    }

    createEntity(): Entity {
        const entity = new Entity(this.lastId++, this.componentManager, this);
        this.entities[entity.id] = entity;
        return entity;
    }

    getOrCreateEntity(entityId: number): Entity {
        let entity = this.getEntity(entityId);
        if (entity) {
            return entity;
        }
        entity = new Entity(entityId, this.componentManager, this);
        this.entities[entity.id] = entity;
        return entity;
    }

    getEntity(id: number): Entity | undefined {
        return this.entities[id];
    }

    removeEntity(id: number): void {
        if (this.entities[id]) {
            // Also remove all components associated with the entity
            this.entities[id].removeAllComponents();
            delete this.entities[id];
        }
    }

    clear(): void {
        for (const entity of this.entities) {
            if (entity) {
                this.removeEntity(entity.id);
            }
        }
    }

    getEntityByName(name: string): Entity | undefined {
        return this.entityNames.get(name);
    }

    getComponentTypesWithDirtyComponents(): Constructor[] {
        return this.componentManager.getComponentTypesWithDirtyComponents();
    }

    getDirtyComponents<T>(componentType: Constructor<T>): { components: T[], entityIds: number[] } {
        return this.componentManager.getDirtyComponents(componentType);
    }


    getEntitiesWithAnyComponentsOf(...componentTypes: Constructor[]): Entity[] {
        const resultEntities = new Set<Entity>();
        for (const componentType of componentTypes) {
            const entities = this.entitiesByComponent.get(componentType);
            if (entities) {
                for (const entity of entities) {
                    resultEntities.add(entity);
                }
            } else {
                return [];
            }
        }
        return Array.from(resultEntities);
    }

    onNameChanged(entity: Entity, newName: string | undefined) {
        if (entity.name) {
            this.entityNames.delete(entity.name);
        }
        if (newName) {
            this.entityNames.set(newName, entity);
        }
    }

    onComponentAdded(entity: Entity, componentType: Constructor) {
        let entities = this.entitiesByComponent.get(componentType);
        if (!entities) {
            entities = [];
            this.entitiesByComponent.set(componentType, entities);
        }
        entities.push(entity);
        this.componentAdded.notify({entityId: entity.id, componentType});
    }

    onComponentRemoved(entity: Entity, componentType: Constructor) {
        const entities = this.entitiesByComponent.get(componentType);
        if (entities) {
            const index = entities.indexOf(entity);
            if (index > -1) {
                entities.splice(index, 1);
            }
        }
        this.componentRemoved.notify({entityId: entity.id, componentType});
    }
}

