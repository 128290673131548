export class SokobanWorld {

    private _gridCellSize: number;
    private _halfGridCellSize: number;
    private worldWidth: number;
    private worldHeight: number;
    private worldUpperLeftX: number;
    private worldUpperLeftY: number;

    private readonly mapWidth: number;
    private readonly mapHeight: number;

    private readonly map: string[];

    public get mapDimensions(): {width: number, height: number} {
        return {width: this.mapWidth, height: this.mapHeight};
    }

    public get(x: number, y: number): string {
        return this.map[y] && this.map[y][x] ? this.map[y][x] : 'X'; // Return 'X' for undefined areas
    }

    public get gridCellSize(): number {
        return this._gridCellSize;
    }

    public get halfGridCellSize(): number {
        return this._halfGridCellSize;
    }

    public get worldDimensions(): {width: number, height: number} {
        return {width: this.worldWidth, height: this.worldHeight};
    }

    public get worldUpperLeft(): {x: number, y: number} {
        return {x: this.worldUpperLeftX, y: this.worldUpperLeftY};
    }

    public convertGridToWorld(gridX: number, gridY: number): {x: number, y: number} {
        return {
            x: gridX * this.gridCellSize + this.halfGridCellSize + this.worldUpperLeftX,
            y: gridY * this.gridCellSize + this.halfGridCellSize + this.worldUpperLeftY
        };
    }

    private initializeMap(inputMap: string[]): string[] {
        const width = Math.max(...inputMap.map(row => row.length));
        const height = inputMap.length;
        let walkableMap = inputMap.map(row => row.split(''));
        let queue: {x: number, y: number}[] = [];

        // Set for tracking visited locations
        let visited: Set<string> = new Set();

        // Find the player's start position and initiate flood fill from there
        for (let y = 0; y < height; y++) {
            for (let x = 0; x < width; x++) {
                if (walkableMap[y][x] === '@' || walkableMap[y][x] === '+') {
                    queue.push({x, y});
                    visited.add(`${x},${y}`);
                    break;
                }
            }
        }

        while (queue.length > 0) {
            const {x, y} = queue.shift()!;

            // Directions: up, down, left, right
            const directions = [
                {dx: 0, dy: -1}, {dx: 0, dy: 1},
                {dx: -1, dy: 0}, {dx: 1, dy: 0}
            ];

            directions.forEach(({dx, dy}) => {
                const nx = x + dx;
                const ny = y + dy;

                if (nx >= 0 && ny >= 0 && nx < width && ny < height) {
                    const key = `${nx},${ny}`;
                    if (!visited.has(key) && (walkableMap[ny][nx] !== '#')) {
                        walkableMap[ny][nx] = 'X';  // Mark as walkable
                        queue.push({x: nx, y: ny});
                        visited.add(key);
                    }
                }
            });
        }

        let processedMap = inputMap.map(row => row.split(''));

        // Convert unvisited spaces to 'X', preserving walls
        for (let y = 0; y < height; y++) {
            for (let x = 0; x < width; x++) {
                if (processedMap[y][x] === ' ' && walkableMap[y][x] !== 'X') {
                    processedMap[y][x] = 'X';  // Only mark non-wall, unvisited cells as 'X'
                }
            }
        }

        return processedMap.map(row => row.join(''));
    }

    constructor(map: string[], gridCellSize: number, public mapId: string) {
        this.map = this.initializeMap(map);

        const width = Math.max(...this.map.map(row => row.length));
        this.mapWidth = width;
        this.mapHeight = this.map.length;

        this._gridCellSize = gridCellSize;
        this._halfGridCellSize = this.gridCellSize / 2;

        const mapWidth = this.mapDimensions.width;
        const mapHeight = this.mapDimensions.height;

        this.worldWidth = mapWidth * this.gridCellSize;
        this.worldHeight = mapHeight * this.gridCellSize;

        this.worldUpperLeftX = -this.worldWidth / 2;
        this.worldUpperLeftY = -this.worldHeight / 2;



    }
}
