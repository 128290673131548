import { Anchor, UiContext } from './ui-context.js';
import { UiElement } from './ui-element.js';
import { InputSystemEvent } from './input-system.js';

export class UiContainer extends UiElement {
    protected children: UiElement[] = [];

    constructor(context: UiContext) {
        super(context);
    }

    addChild(child: UiElement): void {
        child.setParent(this);
        this.children.push(child);
        this._dirty = true;
    }

    // Remove a child element
    removeChild(child: UiElement): void {
        const index = this.children.indexOf(child);
        if (index !== -1) {
            this.children.splice(index, 1);
            child.setParent(null);
        }
        this._dirty = true;
    }

    protected childDimensionsChanged(child: UiElement): void {
        this._dirty = true;
    }

    protected dimensionsChanged(): void {
        // if width or height are auto the calculated width and height are 0
        // if it is a percentage the calculated width and height are the percentage of the parent - tbd
        if (this._width === 'auto') {
            this.calculatedWidth = 0;
        } else if (this._width.endsWith('px')) {
            this.calculatedWidth = parseInt(this._width);
        } else {
            throw new Error(`Not implemented ${this._width}`);
        }
        if (this._height === 'auto') {
            this.calculatedHeight = 0;
        } else if (this._height.endsWith('px')) {
            this.calculatedHeight = parseInt(this._height);
        } else {
            throw new Error(`Not implemented ${this._height}`);
        }
        this._dirty = true;
    }

    public positionChildren(): void {
        this.children.forEach(child => {
            const currentPos = { x: child.position.x, y: child.position.y };
            if (child.positionType === 'absolute') {
                if (child.top !== undefined) {
                    currentPos.y = child.top * this._context.scaleFactor;
                } else if (child.bottom !== undefined) {
                    currentPos.y = this.calculatedHeight - child.calculatedHeight - child.bottom * this._context.scaleFactor;
                }
                if (child.left !== undefined) {
                    currentPos.x = child.left * this._context.scaleFactor;
                } else if (child.right !== undefined) {
                    currentPos.x = this.calculatedWidth - child.calculatedWidth - child.right * this._context.scaleFactor;
                }
            } else if (child.positionType === 'relative') {
                child.position.x += child.left ?? 0;
                child.position.y += child.top ?? 0;
            } else if (child.positionType === 'static') {

            } else {
                throw new Error('Not implemented');
            }
            child.position = { x: Math.round(currentPos.x), y: Math.round(currentPos.y) };
        });
    }

    override layout(): void {
            for (const child of this.children) {
                child.layout();
            }
            this.positionChildren();
            this._dirty = false;

    }

    render(): void {
        if (this._dirty) {
            this.layout();
            this._dirty = false;
        }
        for (const child of this.children) {
            child.render();
        }
    }

    override handleInputEvent(event: InputSystemEvent): boolean {
        for (const child of this.children) {
            if (child.handleInputEvent(event)) {
                return true;
            }
        }
        return false;
    }
}
