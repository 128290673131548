import {Logger} from '../Logger';
import {assertNotNull, IDisposable} from '../common';

export abstract class System implements IDisposable {
    private name: string;

    protected constructor(protected logger: Logger) {
        this.name = this.constructor.name;
        assertNotNull(this.logger, 'logger');
    }

    abstract dispose(): void;

    // This function could be overridden by subclasses to provide specific update logic.
    update(deltaTime: number) {
    }
}
