import {useEffect} from 'preact/hooks';
import {EventBus} from '../../shared/src/eventBus.js';
import {LoggerImpl} from '../../shared/src/Logger.js';
import {loadAssets} from './asset-loader.js';
import {SokobanGameScene, SokobanGameSceneConfig} from './sokoban/sokoban-game-scene.js';
import {
    LifecycleEvent,
    PuzzleToSolve,
    SokobanSceneLifecycle,
    SokobanSceneLifecycleConfig,
} from './sokoban/sokoban-scene-lifecycle.js';
import {SokobanWorld} from './sokoban/sokoban-world.js';

async function fireup(logger: LoggerImpl,
                      eventBus: EventBus,
                      canvas: HTMLCanvasElement,
                      config: SokobanSceneLifecycleConfig,
) {
    const theme = localStorage.getItem('theme');
    logger.info(`theme: ${theme}`);
    const {sheetLoader, animationManager} = await loadAssets(theme ?? 'i1', logger);
    const lc = new SokobanSceneLifecycle(canvas, eventBus, sheetLoader, animationManager, config, logger);
}

function setupCanvas(canvas: HTMLCanvasElement) {
    canvas.style.zIndex = '0';
    canvas.style.imageRendering = 'pixelated';
    canvas.style.position = 'absolute';
    canvas.style.top = '0';
    canvas.style.left = '0';
    document.body.appendChild(canvas);
    const context = canvas.getContext('2d');
    if (!context) {
        return;
    }
    context.imageSmoothingEnabled = false;
    canvas.addEventListener('contextmenu', (e) => console.log('contextmenu', e));
    canvas.addEventListener('contextlost', (e) => console.log('contextlost', e));
    canvas.addEventListener('contextrestored', (e) => console.log('contextrestored', e));
}

export const GameComponent = ({globalLogger, eventBus}: {
    globalLogger: LoggerImpl,
    eventBus: EventBus
}) => {
    useEffect(() => {
        const canvas = document.createElement('canvas');
        setupCanvas(canvas);
        const config: SokobanSceneLifecycleConfig = {replay: false};
        fireup(globalLogger, eventBus, canvas, config);
        return () => {
            document.body.removeChild(canvas);
        };
    }, []);
    return null;
}

export const autoPuzzleFactory = async () => {
    return {
        uniqueId: '1',
        name: 'Test',
        description: 'Test',
        puzzleData:
            '   ####\n' +
            '   #  ########\n' +
            '#### $ $.....#\n' +
            '#   $   ######\n' +
            '#@### ###\n' +
            '#  $  #\n' +
            '# $ # #\n' +
            '## #  #\n' +
            ' #    #\n' +
            ' ######',
        difficulty: 1,
        rating: 1,
        firstShown: new Date(),
        alreadySolved: false,
    };
}

// export const GameComponentAutoReplay = ({globalLogger}: {
//     globalLogger: LoggerImpl
// }) => {
//     useEffect(() => {
//         const canvas = document.createElement('canvas');
//         setupCanvas(canvas);
//         const config: SokobanSceneLifecycleConfig = {replay: true};
//         fireup(globalLogger, autoPuzzleFactory, async (_) => {
//         }, canvas, config);
//         return () => {
//             document.body.removeChild(canvas);
//         };
//     }, []);
//     return null;
// }
