export class RenderInstance {
    a: number;
    b: number;
    c: number;
    d: number;
    e: number;
    f: number;

    protected _scale: number = 1;

    constructor() {
        this.a = 1;
        this.b = 0;
        this.c = 0;
        this.d = 1;
        this.e = 0;
        this.f = 0;
    }

    public getMatrix(): number[] {
        return [this.a, this.b, this.c, this.d, this.e, this.f];
    }

    public setPosition(x: number, y: number): void {
        this.e = x;
        this.f = y;
    }

    public getPosition(): { x: number, y: number } {
        return { x: this.e, y: this.f };
    }

    public setRotation(angle: number): void {
        const cosTheta = Math.cos(angle);
        const sinTheta = Math.sin(angle);

        this.a = cosTheta * this._scale;
        this.b = sinTheta * this._scale;
        this.c = -sinTheta * this._scale;
        this.d = cosTheta * this._scale;
    }

    public setScale(scale: number): void {
        // Update the matrix components to reflect the new scale
        const cosTheta = this.a / this._scale;
        const sinTheta = this.b / this._scale;

        this._scale = scale;

        this.a = cosTheta * this._scale;
        this.b = sinTheta * this._scale;
        this.c = -sinTheta * this._scale;
        this.d = cosTheta * this._scale;
    }


    public render(ctx: CanvasRenderingContext2D): void {
        ctx.transform(this.a, this.b, this.c, this.d, this.e, this.f);
    }
}
