import {assertNotNull} from '../../../shared/src/common.js';
import {DiscreteGridComponent} from '../../../shared/src/ecs/discrete-grid-component.js';
import {Ecs} from '../../../shared/src/ecs/ecs.js';
import {TransformComponent} from '../../../shared/src/ecs/transform-component.js';
import {EventBus} from '../../../shared/src/eventBus.js';
import {TimeUtils} from '../../../shared/src/time-utils.js';
import {loadAssets} from '../asset-loader.js';
import {BrowserConsoleLogger} from '../browser-console-logger.js';
import {AnimationManager} from '../graphics/animation-manager.js';
import {CameraComponent} from '../graphics/camera-component.js';
import {Canvas} from '../graphics/canvas.js';
import {InputComponent} from '../graphics/input-component.js';
import {InputSystem} from '../graphics/input-system.js';
import {Scene} from '../graphics/scene.js';
import {Logger, LoggerImpl} from '../../../shared/src/Logger.js';
import {SpriteSheetLoader} from '../graphics/sprite.js';
import {GridSystem} from './grid-system.js';
import {convertWorldToEntities} from './map-to-entity-converter.js';
import {SokobanBoxComponent} from './sokoban-box-component.js';
import {SokobanCameraSystem} from './sokoban-camera-system.js';
import {NewCommand, SokobanGameCommandDispatcher} from './sokoban-game-command-dispatcher.js';
import {MoveCommand} from './sokoban-game-command.js';
import {SokobanGameInfoComponent} from './sokoban-game-info-component.js';
import {SokobanImmovableComponent} from './sokoban-immovable-component.js';
import {SokobanMoveSystem} from './sokoban-move-system.js';
import {SokobanPlayerInputSystem} from './sokoban-player-input-system.js';
import {SokobanRenderSystem, SpriteComponent} from './sokoban-render-system.js';
import {SokobanTargetComponent} from './sokoban-target-component.js';
import {SokobanUiSystem} from './sokoban-ui-system.js';
import {SokobanWorld} from './sokoban-world.js';

export const RestartCommand = 'RestartCommand';
export const LevelCompletedCommand = 'LevelCompletedCommand';
export const StartNextLevelCommand = 'StartNextLevelCommand';
export const SokobanSceneLifecycleInitialized = 'SokobanSceneLifecycleInitialized';

export interface SokobanGameSceneConfig {
    world: SokobanWorld;
    stats: { firstShown: number, alreadySolved: boolean };
    replay: boolean;
    sheetLoader: SpriteSheetLoader;
    animationManager: AnimationManager;
}


export class SokobanGameScene extends Scene {
    private canvas: Canvas;
    private ecs: Ecs;
    private sheetLoader: SpriteSheetLoader;
    private animationManager: AnimationManager;
    private world: SokobanWorld;
    private stats: { firstShown: number, alreadySolved: boolean };

    get ecsInstance(): Ecs {
        return this.ecs;
    }

    public constructor(private eventBus: EventBus,
                       canvas: HTMLCanvasElement,
                       sokobanGameSceneConfig: SokobanGameSceneConfig,
                       private logger: Logger) {
        assertNotNull(logger, 'logger');
        super();
        this.canvas = new Canvas(canvas, this.eventBus, this.logger);
        this.world = sokobanGameSceneConfig.world;
        this.sheetLoader = sokobanGameSceneConfig.sheetLoader;
        this.animationManager = sokobanGameSceneConfig.animationManager;
        this.stats = sokobanGameSceneConfig.stats;
        this.ecs = new Ecs(this.eventBus, this.logger);
        this.gameLoop = this.gameLoop.bind(this);

        this.ecs.componentRegistry.registerComponent(1, CameraComponent);
        this.ecs.componentRegistry.registerComponent(2, TransformComponent);
        this.ecs.componentRegistry.registerComponent(3, SpriteComponent);
        this.ecs.componentRegistry.registerComponent(4, DiscreteGridComponent);
        this.ecs.componentRegistry.registerComponent(5, SokobanBoxComponent);
        this.ecs.componentRegistry.registerComponent(6, InputComponent);
        this.ecs.componentRegistry.registerComponent(7, SokobanImmovableComponent);
        this.ecs.componentRegistry.registerComponent(8, SokobanTargetComponent);
        this.ecs.componentRegistry.registerComponent(9, SokobanGameInfoComponent);

        this.ecs.addSystem(new SokobanCameraSystem(this.ecs, this.world, this.canvas, this.logger));
        let inputSystem: InputSystem | null = null;
        if (!sokobanGameSceneConfig.replay) {
            inputSystem = new InputSystem(this.ecs, this.canvas, this.logger);
            this.ecs.addSystem(inputSystem);
        }
        this.ecs.addSystem(new SokobanPlayerInputSystem(this.ecs, this.canvas, this.logger));
        this.ecs.addSystem(new SokobanGameCommandDispatcher(this.ecs, this.world, this.logger));
        this.ecs.addSystem(new GridSystem(this.ecs, this.world, this.logger));
        this.ecs.addSystem(new SokobanMoveSystem(this.ecs, this.world, this.logger));
        this.ecs.addSystem(new SokobanRenderSystem(this.ecs, this.sheetLoader, this.animationManager, this.world, this.canvas, this.logger));
        if (!sokobanGameSceneConfig.replay) {
            this.ecs.addSystem(new SokobanUiSystem(this.ecs, this.sheetLoader, this.animationManager, this.world, this.canvas, inputSystem!, this.logger));
        }

        const entity = this.ecs.entityManager.createEntity();
        entity.name = 'gameInfo';
        // if already solved, just show the current time
        const gameInfo = new SokobanGameInfoComponent(this.stats.alreadySolved ? TimeUtils.getCurrentTimestamp() : this.stats.firstShown, this.stats.alreadySolved);
        entity.setComponent(SokobanGameInfoComponent, gameInfo);
        convertWorldToEntities(this.ecs, this.world);
    }

    lastTime = 0;

    gameLoop(currentTime: number) {
        const deltaTime = currentTime - this.lastTime;
        this.lastTime = currentTime;

        if (this.isDisposed()) {
            this.logger.info('SokobanGameScene disposed, stopping game loop');
            return;
        }

        if (!this.ecs.update(deltaTime)) {
            this.logger.error('Throttling game loop');
            setTimeout(() => this.gameLoop(currentTime), 1000);
            return;
        }
        if (!this.isDisposed()) {
            const res = requestAnimationFrame(this.gameLoop);
        } else {
            this.logger.info('SokobanGameScene disposed, stopping game loop');
        }
    }

    async initialize(): Promise<void> {
        requestAnimationFrame(this.gameLoop);
    }

    dispose(): void {
        this.logger.info('Disposing SokobanGameScene');
        super.dispose();
        this.canvas.dispose();
        this.ecs.dispose();
    }

    render(): void {

    }

}
