import {styled} from 'goober';
import {Fragment, h} from 'preact';
import {forwardRef} from 'preact/compat';
import {useEffect, useRef, useState} from 'preact/hooks';

const FullScreenImage = styled('img', forwardRef)`
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.1);
`;

const originalWidth = 1856; // original width of the image
const originalHeight = 2464; // original height of the image

// Coordinates where you want to place the text
const originalTextX = 570; // X coordinate on the original image
const originalTextY = 850; // Y coordinate on the original image


const DynamicText = styled('div')`
    position: absolute;
    top: ${(originalTextY / originalHeight) * 100}%;
    left: ${(originalTextX / originalWidth) * 100}%;
    font-family: 'Capture_it';
    color: #654217;
    font-size: 1.5rem;
    line-height: 1.0;
`;

const imageUrl = new URL(
    '../../assets/lagerhaus.webp',
    import.meta.url
);

export const ImageWithTextComponent = ({text}: {
    text: string
}) => {
    const [position, setPosition] = useState({top: '50%', left: '50%'});
    const [fontSize, setFontSize] = useState('1rem'); // Default font size
    const imageRef = useRef<HTMLImageElement>(null);

    const updateTextPosition = () => {
        const img = imageRef.current;
        if (img) {
            const rect = img.getBoundingClientRect();
            const top = (originalTextY / img.naturalHeight) * rect.height + rect.top;
            const left = (originalTextX / img.naturalWidth) * rect.width + rect.left;

            // Calculate font size relative to image height
            const dynamicFontSize = Math.max(12, rect.height / 30) + 'px'; // Minimum of 12px font size
            setFontSize(dynamicFontSize);

            setPosition({top: `${top}px`, left: `${left}px`});
        }
    };

    useEffect(() => {
        const img = (imageRef.current!) as HTMLImageElement;
        {
            if (img.complete) {
                console.log('Image already loaded');
                updateTextPosition(); // Image already loaded, update immediately
            } else {
                console.log('Image not loaded yet');
                img.addEventListener('load', updateTextPosition); // Wait for image to load
            }

            window.addEventListener('resize', updateTextPosition);
            return () => {
                img.removeEventListener('load', updateTextPosition);
                window.removeEventListener('resize', updateTextPosition);
            };
        }
    }, [originalTextX, originalTextY]);

    return (
        //     <div style={{position: 'relative', height: '100vh', width: '100vw'}}>
        <div style={{height: '100%', width: '100%'}}>
            <FullScreenImage ref={imageRef} src={imageUrl} alt="Background Image"/>
            <DynamicText style={{top: position.top, left: position.left, fontSize}}>
                {text.split('\n').map((line, index) => (
                    <Fragment key={index}>
                        {line}
                        <br/>
                    </Fragment>
                ))}
            </DynamicText>
        </div>
    );
};
