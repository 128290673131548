import {UserType} from '@thomaszeman/web-common/auth';
import {createContext} from "preact";

export type GlobalContextType = {
    //isDebug: boolean;
    //isMobileDevice: boolean;
    //hasPhysicalKeyboard: boolean;
    //timeZone: string;
    user: UserType | null;
    updateUser: () => void;
}

export const GlobalContext = createContext<GlobalContextType>(null as unknown as GlobalContextType);
