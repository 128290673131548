export abstract class Scene {
    private disposed: boolean;
    protected constructor() {
        this.disposed = false;
    }
    public dispose(): void {
        this.disposed = true;
    }
    public isDisposed(): boolean {
        return this.disposed;
    }
    abstract initialize(): Promise<void>;
}
