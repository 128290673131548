import {h, Fragment} from 'preact';
import {styled} from 'goober';
import {forwardRef} from 'preact/compat';
import {MenuContainer, SetButton, SetProgressLabel} from './welcome-screen-styled.js';

export const CreditsComponent = ({onClick}: {onClick: () => void}) => {
    return (
        <MenuContainer>
            <SetProgressLabel>Credits</SetProgressLabel>
            <SetProgressLabel>
                Lagerhaus<br/>part of the nocap.gg series
            </SetProgressLabel>
            <SetButton onClick={onClick}>Back</SetButton>
        </MenuContainer>
    );
}
