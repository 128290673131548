import Hammer from 'hammerjs';
import { IDisposable, Queue } from '../../../shared/src/common.js';
import { Ecs } from '../../../shared/src/ecs/ecs.js';
import { System } from '../../../shared/src/ecs/system.js';
import { Logger } from '../../../shared/src/Logger.js';
import { CameraComponent } from './camera-component.js';
import { Canvas } from './canvas.js';
import { InputComponent, KeyState, MouseButton } from './input-component.js';
import { screenToWorld } from './maths.js';

export enum InputEventType {
    KeyDown,
    KeyUp,
    MouseDown,
    MouseUp,
    MouseMove,
    TouchTap
}

export interface InputSystemEvent {
    type: InputEventType;
    key?: string; // For keyboard events
    button?: MouseButton; // For mouse/touch events
    position?: { x: number, y: number }; // For mouse/touch position
    processed?: boolean; // Flag to indicate if the event has been processed
}

export type Subscriber<T> = (value: T) => boolean;

export class EventHandler<T> {
    private subscribers: Subscriber<T>[] = [];

    public addHandlerEnd(subscriber: Subscriber<T>): void {
        this.subscribers.push(subscriber);
        if (this.subscribers.length > 5) {
            // eslint-disable-next-line no-console
            console.error(`Warning, EventHandler has more than 5 subscribers ${JSON.stringify(this.subscribers)}`);
        }
    }

    public addHandlerFront(subscriber: Subscriber<T>): void {
        this.subscribers.unshift(subscriber);
        if (this.subscribers.length > 5) {
            // eslint-disable-next-line no-console
            console.error(`Warning, EventHandler has more than 5 subscribers ${JSON.stringify(this.subscribers)}`);
        }
    }

    public removeHandler(subscriber: Subscriber<T>): void {
        this.subscribers = this.subscribers.filter((s) => s !== subscriber);
    }

    public runHandlerChain(value: T = (null as T)): void {
        for (const subscriber of this.subscribers) {
            const result = subscriber(value);
            if (result) {
                break;
            }
        }
    }
}

export class InputSystem extends System implements IDisposable {
    private hammer!: HammerManager;
    private events: InputSystemEvent[] = [];
    public onUpdateCycle = new EventHandler<number>();
    public onInputEvent = new EventHandler<InputSystemEvent>();
    private handleInputComponents: HandleInputComponents;

    constructor(private ecs: Ecs, private canvas: Canvas, logger: Logger) {
        super(logger);
        this.setupEventListeners();
        this.setupHammer();
        this.handleInputComponents = new HandleInputComponents(ecs, this, logger);
    }

    dispose() {
        this.handleInputComponents.dispose();
        // Remove keyboard event listeners
        window.removeEventListener('keydown', this.keyDownHandler);
        window.removeEventListener('keyup', this.keyUpHandler);

        // Remove mouse event listeners
        this.canvas.removeEventListener('mousedown', this.mouseDownHandler);
        this.canvas.removeEventListener('mouseup', this.mouseUpHandler);
        this.canvas.removeEventListener('mousemove', this.mouseMoveHandler);

        // Remove Hammer.js tap event listener
        if (this.hammer) {
            this.hammer.off('tap', this.tapHandler);
            this.hammer.destroy();
        }
    }

    private keyDownHandler = (event: KeyboardEvent) => this.onKeyDown(event);
    private keyUpHandler = (event: KeyboardEvent) => this.onKeyUp(event);
    private mouseDownHandler = (event: MouseEvent) => this.onMouseDown(event);
    private mouseUpHandler = (event: MouseEvent) => this.onMouseUp(event);
    private mouseMoveHandler = (event: MouseEvent) => this.onMouseMove(event);
    private tapHandler = (event: HammerInput) => this.onTap(event);

    private setupEventListeners(): void {
        // Handle keyboard events
        window.addEventListener('keydown', this.keyDownHandler);
        window.addEventListener('keyup', this.keyUpHandler);

        // Handle mouse events on the canvas
        this.canvas.addEventListener('mousedown', this.mouseDownHandler);
        this.canvas.addEventListener('mouseup', this.mouseUpHandler);
        this.canvas.addEventListener('mousemove', this.mouseMoveHandler);
    }

    private setupHammer(): void {
        this.hammer = new Hammer(this.canvas.element);

        // Handle tap event as equivalent to mouse down
        this.hammer.on('tap', (event: HammerInput) => this.onTap(event));
    }

    private onKeyDown(event: KeyboardEvent): void {
        this.events.push({ type: InputEventType.KeyDown, key: event.code });
    }

    private onKeyUp(event: KeyboardEvent): void {
        this.events.push({ type: InputEventType.KeyUp, key: event.code });
    }

    private onMouseDown(event: MouseEvent): void {

        this.events.push({
            type: InputEventType.MouseDown,
            button: event.button as MouseButton,
            position: { x: (event.offsetX * devicePixelRatio), y: (event.offsetY * devicePixelRatio) },
        });
    }

    private onMouseUp(event: MouseEvent): void {

        this.events.push({
            type: InputEventType.MouseUp,
            button: event.button as MouseButton,
            position: { x: (event.offsetX * devicePixelRatio), y: (event.offsetY * devicePixelRatio) },
        });
    }

    private onMouseMove(event: MouseEvent): void {
        this.events.push({
            type: InputEventType.MouseMove,
            position: { x: (event.offsetX * devicePixelRatio), y: (event.offsetY * devicePixelRatio) },
        });
    }

    private onTap(event: HammerInput): void {
        const rect = this.canvas.clientBoundingRect;
        const x = event.center.x - rect.left;
        const y = event.center.y - rect.top;
        this.events.push({
            type: InputEventType.TouchTap,
            button: MouseButton.Left, // Treat tap as left click
            position: { x: (x * devicePixelRatio), y: (y * devicePixelRatio) },
        });
    }

    update(delta: number): void {
        this.onUpdateCycle.runHandlerChain(delta);

        const processedKeys = new Set<string>();
        const processedButtons = new Set<MouseButton>();

        for (const event of this.events) {
            if (event.processed) continue; // Skip already processed events

            switch (event.type) {
                case InputEventType.KeyUp:
                case InputEventType.KeyDown:
                    if (!processedKeys.has(event.key!)) {
                        this.onInputEvent.runHandlerChain(event);
                        processedKeys.add(event.key!);
                        event.processed = true;
                    }
                    break;
                case InputEventType.MouseUp:
                case InputEventType.MouseDown:
                    if (!processedButtons.has(event.button!)) {
                        this.onInputEvent.runHandlerChain(event);
                        processedButtons.add(event.button!);
                        event.processed = true;
                    }
                    break;
                case InputEventType.MouseMove:
                    this.onInputEvent.runHandlerChain(event);
                    event.processed = true;
                    break;
                case InputEventType.TouchTap:
                    this.onInputEvent.runHandlerChain(event);
                    event.processed = true;
                    this.events.push({
                        type: InputEventType.MouseUp,
                        button: MouseButton.Left,
                        position: event.position,
                    });
                    break;
            }
        }

        // Clean up processed events
        this.events = this.events.filter(event => !event.processed);
    }
}

export class HandleInputComponents implements IDisposable {
    constructor(private ecs: Ecs, private inputSystem: InputSystem, private logger: Logger) {
        this.handleInputEvent = this.handleInputEvent.bind(this);
        this.handleUpdateCycleEvent = this.handleUpdateCycleEvent.bind(this);
        this.inputSystem.onInputEvent.addHandlerEnd(this.handleInputEvent);
        this.inputSystem.onUpdateCycle.addHandlerEnd(this.handleUpdateCycleEvent);
    }

    public dispose(): void {
        this.inputSystem.onInputEvent.removeHandler(this.handleInputEvent);
        this.inputSystem.onUpdateCycle.removeHandler(this.handleUpdateCycleEvent);
    }

    private getAllInputs(): InputComponent[] {
        return this.ecs.entityManager.getComponentManager().getComponents<InputComponent>(InputComponent).components;
    }

    handleUpdateCycleEvent(delta: number): boolean {
        this.getAllInputs().forEach(input => {
            // Update key states
            input.keyStates.forEach((state, key) => {
                if (state === KeyState.Down) {
                    input.setKeyState(key, KeyState.Held);
                } else if (state === KeyState.Released) {
                    input.setKeyState(key, KeyState.Up);
                }
            });

            // Update mouse button states
            input.mouseButtonStates.forEach((state, button) => {
                if (state === KeyState.Down) {
                    input.setMouseButtonState(button, KeyState.Held);
                } else if (state === KeyState.Released) {
                    input.setMouseButtonState(button, KeyState.Up);
                }
            });
        });
        return true;
    }

    private handleInputEvent(event: InputSystemEvent): boolean {
        switch (event.type) {
            case InputEventType.KeyDown:
                this.getAllInputs().forEach(input => input.setKeyState(event.key!, KeyState.Down));
                break;
            case InputEventType.KeyUp:
                this.getAllInputs().forEach(input => input.setKeyState(event.key!, KeyState.Released));
                break;
            case InputEventType.MouseDown:
                this.getAllInputs().forEach(input => {
                    input.setMouseButtonState(event.button!, KeyState.Down);
                    input.setClickState(true, event.position!.x, event.position!.y);
                });
                break;
            case InputEventType.MouseUp:
                this.getAllInputs().forEach(input => {
                    input.setMouseButtonState(event.button!, KeyState.Released);
                    input.setClickState(false, event.position!.x, event.position!.y);
                });
                break;
            case InputEventType.MouseMove:
                this.getAllInputs().forEach(input => {
                    input.updateMousePosition(event.position!.x, event.position!.y);
                });
                break;
            case InputEventType.TouchTap:
                this.getAllInputs().forEach(input => {
                    input.setMouseButtonState(MouseButton.Left, KeyState.Down);
                    input.setClickState(true, event.position!.x, event.position!.y);
                });
                break;
        }
        return true;
    }
}
