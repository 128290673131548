import {LoggerImpl} from '../../shared/src/Logger.js';
import {loadBundledJson} from './bundle-helper.js';
import {AnimationManager, ThemeConfig} from './graphics/animation-manager.js';
import {SpriteSheetLoader} from './graphics/sprite.js';

let cachedSheetLoader: SpriteSheetLoader | null = null;
let cachedAnimationManager: AnimationManager | null = null;
let cachedTheme: string | null = null;
let cachePopulated = false;

export async function loadAssets(theme: string, logger: LoggerImpl): Promise<{sheetLoader: SpriteSheetLoader, animationManager: AnimationManager}> {
    if (cachedTheme !== theme) {
        cachePopulated = false;
    }
    if (cachePopulated) {
        return {sheetLoader: cachedSheetLoader!, animationManager: cachedAnimationManager!};
    }
    cachedTheme = theme;
    const sheetLoader = new SpriteSheetLoader();
    logger.info('Loading assets');
    const root = await loadBundledJson<ThemeConfig>('root.json');
    logger.info('Root loaded');
    const animationManager = new AnimationManager(root[theme], (n) => sheetLoader.getSpriteSheet(n));
    logger.info('Animation manager created');
    await animationManager.initialize();
    logger.info('Animation manager initialized');
    cachedSheetLoader = sheetLoader;
    cachedAnimationManager = animationManager;

    cachePopulated = true;
    return {sheetLoader, animationManager};
}
