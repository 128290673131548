export function getEnvironment() {
    if (typeof process !== 'undefined' && process.versions && process.versions.node) {
        return 'node';
    } else if (typeof window !== 'undefined' && window.document) {
        return 'browser';
    } else {
        return 'unknown';
    }
}

import { SyncedEventsType} from "./syncedDecorator";

export const EMPTY = 0;
export const PLAYER_X = 1;
export const PLAYER_O = 2;
export const PLAYER_OBSERVER = 3;
export const PLAYER_NONE = 4;

export enum Role {
    PLAYER_X = 1,
    PLAYER_O = 2,
    PLAYER_OBSERVER = 3,
    PLAYER_NONE = 4,
    PLAYER_GAME_OVER = 5,
}

export type ClientId = number;

// ------ CLIENT TO SERVER MESSAGES ------

export enum ClientMessage {
    HELLO = "HELLO",
    JOIN_GAME = "JOIN_GAME",
    LEAVE_GAME = "LEAVE_GAME",
    MAKE_MOVE = "MAKE_MOVE",
    CREATE_GAME = "CREATE_GAME",
}

// export type AnyClientMessageType = ClientMessage.HELLO | ClientMessage.MAKE_MOVE | ClientMessage.JOIN_GAME | ClientMessage.CREATE_GAME;

export type ClientHello = {
    type: ClientMessage.HELLO;
    nickName: string;
    token: string;
}

export type ClientJoinGame = {
    type: ClientMessage.JOIN_GAME;
    gameId: string;
}

export type ClientLeaveGame = {
    type: ClientMessage.LEAVE_GAME;
    gameId: string;
}

export type ClientCreateGame = {
    type: ClientMessage.CREATE_GAME;
}

export type ClientMakeMove = {
    type: ClientMessage.MAKE_MOVE;
    position: number;
}

export type AnyClientMessage = ClientHello | ClientJoinGame | ClientLeaveGame | ClientCreateGame | ClientMakeMove

// ------ SERVER TO CLIENT MESSAGES ------

export enum ServerMessage {
    HELLO = "SERVER_HELLO",
    GENERAL_ERROR = "SERVER_GENERAL_ERROR",
    GAME_ASSIGNED = "SERVER_GAME_ASSIGNED",
    GAME_NOT_FOUND = "SERVER_GAME_NOT_FOUND",
    GAME_LOGIC_EVENT = "GAME_LOGIC_EVENT",
    SERVER_GAME_ROOM_STATE = "SERVER_GAME_ROOM_STATE"
}

export enum GameLifecycleState {
    WAITING_FOR_PLAYERS = "WAITING_FOR_PLAYERS",
    GAME_STARTED = "GAME_STARTED",
    GAME_OVER = "GAME_OVER",
    WAITING_TO_RESTART = "WAITING_TO_RESTART",
}

export enum ServerGameRoomEventSubType {
    PLAYER_JOINED = "PLAYER_JOINED",
    PLAYER_LEFT = "PLAYER_LEFT",
    GAME_LIFECYCLE_STATE_CHANGED = "GAME_LIFECYCLE_STATE_CHANGED",
    PLAYER_INFO_CHANGED = "PLAYER_INFO_CHANGED",
}

export type ServerHello = {
    type: ServerMessage.HELLO;
    clientId: ClientId;
}

export type ServerGeneralError = {
    type: ServerMessage.GENERAL_ERROR;
    message: string;
}

export type ServerGameAssigned = {
    type: ServerMessage.GAME_ASSIGNED;
    gameArenaName: string;
}

export type ServerGameNotFound = {
    type: ServerMessage.GAME_NOT_FOUND;
    gameId: string;
}

export type ServerClient = { nickname: string, score: number, id: ClientId, role: Role };

export type ServerGameRoomState = {
    type: ServerMessage.SERVER_GAME_ROOM_STATE;
    snapshot?: Record<string, any>;
    events?: SyncedEventsType;
}

export type ServerGameLogicEvent = {
    type: ServerMessage.GAME_LOGIC_EVENT;
};

export type AnyServerMessage =
    ServerHello
    | ServerGeneralError
    | ServerGameAssigned
    | ServerGameRoomState
    | ServerGameNotFound
    | ServerGameLogicEvent;

// ------ UTIL ------

export class Queue<T> {
    private enqueueStack: T[];
    private dequeueStack: T[];

    constructor() {
        this.enqueueStack = [];
        this.dequeueStack = [];
    }

    enqueue(t: T) {
        this.enqueueStack.push(t);
    }

    dequeue(): T | undefined {
        if (this.dequeueStack.length === 0) {
            while (this.enqueueStack.length > 0) {
                this.dequeueStack.push(this.enqueueStack.pop() as T);
            }
        }
        return this.dequeueStack.pop();
    }

    peek(): T | undefined {
        if (this.dequeueStack.length === 0) {
            while (this.enqueueStack.length > 0) {
                this.dequeueStack.push(this.enqueueStack.pop() as T);
            }
        }
        return this.dequeueStack[this.dequeueStack.length - 1];
    }

    isEmpty() {
        return this.enqueueStack.length === 0 && this.dequeueStack.length === 0;
    }

    size() {
        return this.enqueueStack.length + this.dequeueStack.length;
    }
}

// ------ GAME STATE ------

export function getEnumName(enumType: object, value: number): string | undefined {
    const foundEntry = Object.entries(enumType).find(([_, enumValue]) => enumValue === value);
    return foundEntry ? foundEntry[0] : undefined;
}

export function assertNotNull<T>(value: T | null, errorMessage = "Value cannot be null"): T {
    if (!value) {
        throw new Error(errorMessage);
    }
    return value;
}

export function assertDefined<T>(value: T | undefined, errorMessage = "Value cannot be undefined"): T {
    if (!value) {
        throw new Error(errorMessage);
    }
    return value;
}


export function assertEqualOrGreaterThan0(value: number, errorMessage = "Value cannot be less than 0"): number {
    if (value < 0) {
        throw new Error(errorMessage);
    }
    return value;
}

// ---

export interface IDisposable {
    dispose(): void;
}

//

export const getHighResolutionTimestamp = (() => {
    if (typeof performance !== "undefined") {
        // Browser environment
        return () => performance.now();
    } else if (typeof process === "undefined") {
        // Fallback for unknown environment
        return () => new Date().getTime();
    } else {
        // Node.js environment
        return () => {
            const hrTime = process.hrtime();
            return (hrTime[0] * 1000) + (hrTime[1] / 1000000);
        };
    }
})();

