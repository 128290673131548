import {ComponentRegistry} from './componentRegistry.js';
import {Constructor} from './ecs.js';
import {assertEqualOrGreaterThan0} from '../common.js';

export class ComponentManager {
    private componentStores: Map<Constructor, any[]> = new Map();
    private dirtyFlags: Map<Constructor, Set<number>> = new Map();
    private componentTypesRefCounter: Map<Constructor, number> = new Map();
    private activeEntities: Set<number> = new Set();

    constructor(private _componentRegistry: ComponentRegistry) {
    }

    get componentRegistry() {
        return this._componentRegistry;
    }

    // returns true if the component was added, false if it was already there
    setComponent<T>(entityId: number, componentType: Constructor<T>, componentInstance: T): boolean {
        if (!this.componentRegistry.has(componentType)) {
            throw new Error(`Component ${componentType.name} is not registered`);
        }
        // Add the component to the component store; setup dirty flags
        let componentStore = this.componentStores.get(componentType);
        let dirtyFlags = this.dirtyFlags.get(componentType);
        if (!componentStore || !dirtyFlags) {
            componentStore = [];
            dirtyFlags = new Set();
            this.componentStores.set(componentType, componentStore);
            this.dirtyFlags.set(componentType, dirtyFlags);
        }

        const alreadyHasComponent = componentStore[entityId] !== undefined;
        componentStore[entityId] = componentInstance;
        dirtyFlags.add(entityId);

        if (!alreadyHasComponent) {
            // Reference counting for the component type
            const refCount = this.componentTypesRefCounter.get(componentType);
            if (!refCount) {
                this.componentTypesRefCounter.set(componentType, 1);
            } else {
                this.componentTypesRefCounter.set(componentType, refCount + 1);
            }
        }

        // Track active entities
        this.activeEntities.add(entityId);

        return !alreadyHasComponent;
    }

    markComponentDirty(entityId: number, componentType: Constructor) {
        const dirtyComponentSet = this.dirtyFlags.get(componentType);
        if (dirtyComponentSet) {
            dirtyComponentSet.add(entityId);
        }
    }

    markComponentClean(entityId: number, componentType: Constructor) {
        const dirtyComponentSet = this.dirtyFlags.get(componentType);
        if (dirtyComponentSet) {
            dirtyComponentSet.delete(entityId);
        }
    }

    markAllComponentsClean(componentType: Constructor) {
        const dirtyComponentSet = this.dirtyFlags.get(componentType);
        if (dirtyComponentSet) {
            dirtyComponentSet.clear();
        }
    }

    markAllClean() {
        for (const dirtyComponentSet of this.dirtyFlags.values()) {
            dirtyComponentSet.clear();
        }
    }

    getComponent<T>(entityId: number, componentType: Constructor): T | undefined {
        const componentStore = this.componentStores.get(componentType);
        if (componentStore) {
            return componentStore[entityId];
        }
        return undefined;
    }

    // returns true if the component was removed, false if it was not there
    removeComponent(entityId: number, componentType: Constructor): boolean {
        let removed = false;
        const componentStore = this.componentStores.get(componentType);
        if (componentStore) {
            if (componentStore[entityId]) {
                removed = true;
                delete componentStore[entityId];
            }
        }
        this.componentTypesRefCounter.set(componentType, assertEqualOrGreaterThan0(this.componentTypesRefCounter.get(componentType)! - 1));
        // Check if the entity has any other components
        let hasOtherComponents = false;
        for (const componentStore2 of this.componentStores.values()) {
            if (componentStore2[entityId]) {
                hasOtherComponents = true;
                break;
            }
        }

        if (!hasOtherComponents) {
            this.activeEntities.delete(entityId);
        }
        return removed;
    }

    getComponents<T>(componentType: Constructor<T>): { components: T[], entityIds: number[] } {
        const componentStore = this.componentStores.get(componentType);
        if (!componentStore) {
            return {components: [], entityIds: []};
        }

        // Only return components for active entities
        const components: T[] = [];
        const entityIds: number[] = [];
        for (const entityId of this.activeEntities) {
            const component = componentStore[entityId];
            if (component) {
                components.push(component);
                entityIds.push(entityId);
            }
        }
        return {components, entityIds};
    }

    getComponentTypesWithDirtyComponents(): Constructor[] {
        const componentTypes: Constructor[] = [];
        for (const [componentType, dirtyFlags] of this.dirtyFlags.entries()) {
            if (dirtyFlags.size > 0) {
                componentTypes.push(componentType);
            }
        }
        return componentTypes;
    }

    isComponentDirty(entityId: number, componentType: Constructor): boolean {
        const dirtyFlags = this.dirtyFlags.get(componentType);
        if (dirtyFlags) {
            return dirtyFlags.has(entityId);
        }
        return false;
    }

    getDirtyComponents<T>(componentType: Constructor<T>): { components: T[], entityIds: number[] } {
        const componentStore = this.componentStores.get(componentType);
        if (!componentStore) {
            return {components: [], entityIds: []};
        }

        // Only return components for active entities
        const dirtyFlags = this.dirtyFlags.get(componentType);
        if (!dirtyFlags) {
            return {components: [], entityIds: []};
        }
        const components: T[] = [];
        const entityIds: number[] = [];
        for (const entityId of dirtyFlags) {
            const component = componentStore[entityId];
            if (component) {
                components.push(component);
                entityIds.push(entityId);
            }
        }
        return {components, entityIds};
    }

    getAllComponentTypes(): Constructor[] {
        return Array.from(this.componentTypesRefCounter.entries())
            .filter(([componentType, refCounter]) => refCounter > 0)
            .map(([componentType]) => componentType);
    }
}
