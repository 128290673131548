import { ComponentManager } from './componentManager';
import { EntityManager } from './entityManager.js';
import { ComponentRegistry } from './componentRegistry.js';
import { System } from './system.js';
import { EventBus } from '../eventBus.js';
import { IDisposable } from '../common.js';
import { SmartBuffer } from 'smart-buffer';
import { Logger } from '../Logger.js';

export interface Constructor<T = any> {
    new(...args: any[]): T; // constructor signature
    deserialize(smartBuffer: SmartBuffer): T; // optional static method
}

export class Ecs implements IDisposable {
    get componentRegistry(): ComponentRegistry {
        return this._componentRegistry;
    }

    get entityManager(): EntityManager {
        return this._entityManager;
    }

    get eventBus(): EventBus {
        return this._eventBus;
    }

    private systems: System[];
    private _entityManager: EntityManager;
    private _componentRegistry: ComponentRegistry;
    private _eventBus: EventBus;
    private _isDisposed = false;
    // private _updateInProgress: boolean = false;
    // private _updateFrameRequested: boolean = false;

    constructor(eventBus: EventBus, private logger: Logger) {
        this.systems = [];
        this._componentRegistry = new ComponentRegistry();
        this._entityManager = new EntityManager(this._componentRegistry);
        this._eventBus = eventBus;
        this.requestUpdate = this.requestUpdate.bind(this);
        this.update = this.update.bind(this);
        this.eventBus.on('requestUpdateFrame', this.requestUpdate);
    }

    dispose() {
        this.logger.debug('Disposing ECS');
        this.eventBus.off('requestUpdateFrame', this.requestUpdate);
        // dispose all systems
        for (const system of this.systems) {
            system.dispose();
        }
        this.systems = [];
        this._entityManager.clear();
        this.eventBus.assertNoListeners();
    }

    addSystem(system: System) {
        this.systems.push(system);
    }

    requestUpdate() {
        if (typeof window !== 'undefined') {
            // Browser environment
            requestAnimationFrame(this.update);
        } else if (typeof require === 'function') {
            // Node.js environment
            this.update(0);
        }
    }

    update(deltaTime: number) {
        if (this._isDisposed) {
            this.logger.warn('ECS is disposed, skipping update loop');
            return false;
        }
        try {
            for (const system of this.systems) {
                system.update(deltaTime);
            }
        } catch (e) {
            this.logger.error('Error during update loop', e);
            return false;
        }
        return true;
    }
}
