import { SmartBuffer } from 'smart-buffer';
import { TimeUtils } from '../../../shared/src/time-utils.js';

export class SokobanGameInfoComponent {

    public mapStartTimeStamp: number = 0;
    public steps: number = 0;
    public pushes: number = 0;
    public gameRunning: boolean = true;
    public solution: string = '';
    public alreadySolved: boolean;

    constructor(_mapStartTimeStamp: number, _alreadySolved: boolean) {
        this.mapStartTimeStamp = _mapStartTimeStamp;
        this.alreadySolved = _alreadySolved;
    }

    incrementSteps() {
        this.steps++;
    }

    incrementPushes() {
        this.pushes++;
    }

    getElapsedTime() {
        return TimeUtils.getCurrentTimestamp() - this.mapStartTimeStamp;
    }

    setGameRunning(running: boolean) {
        this.gameRunning = running;
    }

    addSolutionStep(step: string) {
        this.solution += step;
    }

    static deserialize(smartBuffer: SmartBuffer) {
    }
}

