export type TimerCallback = () => void;

export interface Timer {
    id: number;
    callback: TimerCallback;
    interval: number;
    remainingTime: number;
    repeat: boolean;
}

export class TimerManager {
    private timers: Timer[] = [];
    private lastId: number = 0;

    update(delta: number): void {
        // Update remaining time for each timer and execute callbacks if needed
        this.timers.forEach(timer => {
            timer.remainingTime -= delta;
            if (timer.remainingTime <= 0) {
                timer.callback();
                if (timer.repeat) {
                    timer.remainingTime += timer.interval;
                } else {
                    this.clearTimeout(timer.id); // Remove the timer if it's not repeating
                }
            }
        });
    }

    setTimeout(callback: TimerCallback, delay: number): number {
        return this.createTimer(callback, delay, false);
    }

    setInterval(callback: TimerCallback, interval: number): number {
        return this.createTimer(callback, interval, true);
    }

    clearTimeout(id: number): void {
        this.timers = this.timers.filter(timer => timer.id !== id);
    }

    clearInterval(id: number): void {
        this.clearTimeout(id); // clearInterval is functionally identical to clearTimeout
    }

    private createTimer(callback: TimerCallback, time: number, repeat: boolean): number {
        const timer: Timer = {
            id: ++this.lastId,
            callback: callback,
            interval: time,
            remainingTime: time,
            repeat: repeat
        };
        this.timers.push(timer);
        return timer.id;
    }
}
