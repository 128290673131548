import {Fragment, h} from 'preact';
import {useCallback, useContext, useEffect, useRef, useState} from 'preact/hooks';
import {route} from 'preact-router';
import {EventBus} from '../../shared/src/eventBus.js';
import {LoggerImpl} from '../../shared/src/Logger.js';
import {BrowserConsoleLogger} from './browser-console-logger.js';
import {loadBundledJson} from './bundle-helper.js';
import {GlobalContext} from './global-context.js';
import {NotFound} from './not-found-page.js';
import {PuzzleCompletedDialog} from './puzzle-completed-dialog.js';
import {
    LevelCompletedCommand,
    SokobanSceneLifecycleInitialized,
    StartNextLevelCommand,
} from './sokoban/sokoban-game-scene.js';
import {
    LifecycleLevelCompleted,
    PuzzleToSolve,
} from './sokoban/sokoban-scene-lifecycle.js';
import {GameComponent} from './game-component.js';
import {getProgressForSet, loadProgress, saveProgress} from './user-progress-persistence.js';

export enum GameScreenState {
    Playing,
    Congratulations
}

const globalLogger = new LoggerImpl();
globalLogger.registerSink(new BrowserConsoleLogger());

const setToBundleName = (setName: string) => {
    switch (setName) {
        case 'microban':
            return 'microban.json';
        case 'microban2':
            return 'microban2.json';
        case 'microban3':
            return 'microban3.json';
        default:
            return null;
    }
}

export const LocalGameScreen = ({setName, puzzleNoStr}: { setName: string, puzzleNoStr: string }) => {
    const [eventBus, setEventBus] = useState<EventBus>(new EventBus(globalLogger, 'local-game-screen'));
    const [gameScreenState, setGameScreenState] = useState(GameScreenState.Playing);
    const [completedStats, setCompletedStats] = useState({steps: 0, pushes: 0, time: 0, solution: ''});
    const [puzzle, setPuzzle] = useState<PuzzleToSolve | null>(null);
    const [setAndNo, setSetAndNo] = useState({setName: setName, puzzleNo: parseInt(puzzleNoStr)});

    if (isNaN(setAndNo.puzzleNo) || setAndNo.puzzleNo < 1 || setAndNo.puzzleNo > 155) {
        // If id is not a valid number, render the NotFound component instead
        return <NotFound/>;
    }

    const puzzleRef = useRef(puzzle);
    const setAndNoRef = useRef(setAndNo);

    const OnLevelCompleted = useCallback(async (event2: any) => {
        const nextPuzzleNo = setAndNoRef.current.puzzleNo + 1;
        setSetAndNo({setName: setName, puzzleNo: nextPuzzleNo});
        await saveProgress(setName, nextPuzzleNo);
        const ev1 = event2 as LifecycleLevelCompleted;
        setGameScreenState(GameScreenState.Congratulations);
        setCompletedStats({steps: ev1.steps, pushes: ev1.pushes, time: ev1.time, solution: ev1.solution});
    }, [setAndNo]);

    const OnSokobanSceneLifecycleInitialized = () => {
        puzzleFactory().then((asd) => {
            eventBus.emit(StartNextLevelCommand, asd);
        });
    };

    useEffect(() => {
        if (Number.isNaN(setAndNo.puzzleNo)) {
            route('/');
        }
        eventBus.on(LevelCompletedCommand, OnLevelCompleted);
        eventBus.on(SokobanSceneLifecycleInitialized, OnSokobanSceneLifecycleInitialized);
        return () => {
            eventBus.off(LevelCompletedCommand, OnLevelCompleted);
            eventBus.off(SokobanSceneLifecycleInitialized, OnSokobanSceneLifecycleInitialized);
            eventBus.dispose();
        };
    }, []);

    useEffect(() => {
        puzzleRef.current = puzzle;
    }, [puzzle]);

    useEffect(() => {
        setAndNoRef.current = setAndNo;
    }, [setAndNo]);

    const puzzleFactory = useCallback(async () => {
        let res;
        let puzzleNo = setAndNoRef.current.puzzleNo;
        const progress = await getProgressForSet(setName);
        console.log(`Progress for ${setName}: ${progress}`);
        if (!progress) {
            puzzleNo = 1;
        } else if (puzzleNo > progress) {
            puzzleNo = progress;
        }
        const set = await loadBundledJson('microban.json') as PuzzleToSolve[];
        res = set.find((p) => p.uniqueId === `microban-${puzzleNo}`);
        res!.firstShown = Date.now();
        window.history.pushState(null, "", `/${setName}/${puzzleNo}`);
        setSetAndNo({setName: setName, puzzleNo: puzzleNo});
        if (!res) {
            globalLogger.error('Failed to fetch puzzle');
            route('/');
            return null;
        }
        setPuzzle(res);
        return res;
    }, [setAndNo]);

    const onPuzzleCompletedNext = useCallback(async () => {

        setGameScreenState(GameScreenState.Playing);
        puzzleFactory().then((asd) => {
            eventBus.emit(StartNextLevelCommand, asd);
        });
    }, []);

    const context = useContext(GlobalContext);

    return (
        <Fragment>
            <GameComponent eventBus={eventBus!} globalLogger={globalLogger}/>
            {gameScreenState === GameScreenState.Congratulations ?
                <PuzzleCompletedDialog playerName={context.user?.nickname!} steps={completedStats.steps}
                                       pushes={completedStats.pushes} time={completedStats.time}
                                       onNext={onPuzzleCompletedNext}/> : null}
        </Fragment>
    );
};
