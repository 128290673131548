import {ComponentManager} from './componentManager.js';

import {EntityManager} from './entityManager.js';
import {Constructor} from './ecs.js';

export class Entity {
    private readonly _id: number;
    private _name: string | undefined;
    private readonly componentManager: ComponentManager;
    private readonly entityManager: EntityManager;

    get name(): string | undefined {
        return this._name;
    }

    set name(newName: string | undefined) {
        if (this._name !== newName) {
            this.entityManager.onNameChanged(this, newName);
            this._name = newName;
        }
    }

    constructor(id: number, componentManager: ComponentManager, entityManager: EntityManager) {
        this._id = id;
        this.componentManager = componentManager;
        this.entityManager = entityManager;
    }

    get id(): number {
        return this._id;
    }

    setComponent<T>(componentType: Constructor, componentInstance: T) {
        if (componentInstance === undefined || componentInstance === null) { throw new Error(`Component instance is undefined`); }
        if (!(componentInstance.constructor === componentType)) { throw new Error(`Component instance is not an instance of ${componentType.name}`); }
        const wasAdded = this.componentManager.setComponent<T>(this.id, componentType, componentInstance);
        if (wasAdded) {
            this.entityManager.onComponentAdded(this, componentType);
        }
    }

    isComponentDirty(componentType: Constructor): boolean {
        return this.componentManager.isComponentDirty(this.id, componentType);
    }

    getComponent<T>(componentType: Constructor): T | undefined {
        return this.componentManager.getComponent<T>(this.id, componentType);
    }

    markComponentDirty(componentType: Constructor) {
        this.componentManager.markComponentDirty(this.id, componentType);
    }

    markComponentClean(componentType: Constructor) {
        this.componentManager.markComponentClean(this.id, componentType);
    }

    hasComponent(componentType: Constructor): boolean {
        return this.componentManager.getComponent(this.id, componentType) !== undefined;
    }

    removeComponent(componentType: Constructor) {
        if (this.componentManager.removeComponent(this.id, componentType)) {
            this.entityManager.onComponentRemoved(this, componentType);
        }
    }

    removeAllComponents() {
        const componentTypes = this.componentManager.getAllComponentTypes();
        for (const componentType of componentTypes) {
            if (this.componentManager.getComponent(this.id, componentType)) {
                this.removeComponent(componentType);
            }
        }
    }
}
