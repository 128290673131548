import { Logger } from '../../../shared/src/Logger.js';
import { IDisposable } from '../../../shared/src/common.js';
import { Ecs } from '../../../shared/src/ecs/ecs.js';
import { EntityComponentGroup } from '../../../shared/src/ecs/entityComponentGroup.js';
import { ModelComponent } from '../../../shared/src/ecs/modelComponent.js';
import { TransformComponent } from '../../../shared/src/ecs/transform-component.js';
import { System } from '../../../shared/src/ecs/system.js';
import { AnimationManager } from '../graphics/animation-manager.js';
import { CameraComponent } from '../graphics/camera-component.js';
import { Canvas } from '../graphics/canvas.js';
import { RenderEngine } from '../graphics/render-engine.js';
import { Sprite, SpriteSheetLoader } from '../graphics/sprite.js';
import { SokobanBoxComponent } from './sokoban-box-component.js';
import { EventMoveFinished } from './sokoban-move-system.js';
import { SokobanWorld } from './sokoban-world.js';

export class SpriteComponent {
    animation: string = 'idle';

    constructor(public item: string) {
    }

    static deserialize(): ModelComponent {
        throw new Error('Not implemented');
    }
}

export class SokobanRenderSystem extends System implements IDisposable {
    private stuff: EntityComponentGroup;
    private boxGroup: EntityComponentGroup;
    private entities: Map<number, Sprite> = new Map<number, Sprite>();
    private boxEntities: Set<number> = new Set<number>();

    private renderEngine: RenderEngine;

    constructor(private ecs: Ecs, private sheetCache: SpriteSheetLoader, private animationManager: AnimationManager, private sokobanWorld: SokobanWorld, private canvas: Canvas, logger: Logger) {
        super(logger);
        this.renderEngine = new RenderEngine(this.canvas, this.logger);
        this.entityAdded = this.entityAdded.bind(this);
        this.entityRemoved = this.entityRemoved.bind(this);
        this.messageReceived = this.messageReceived.bind(this);
        this.ecs.eventBus.on(EventMoveFinished, this.messageReceived);
        this.stuff = new EntityComponentGroup(ecs.entityManager, [SpriteComponent, TransformComponent]);
        this.boxGroup = new EntityComponentGroup(ecs.entityManager, [SpriteComponent, TransformComponent, SokobanBoxComponent]);
        this.stuff.entityAdded.subscribe(this.entityAdded);
        this.stuff.entityRemoved.subscribe(this.entityRemoved);
        this.boxAdded = this.boxAdded.bind(this);
        this.boxRemoved = this.boxRemoved.bind(this);
        this.boxGroup.entityAdded.subscribe(this.boxAdded);
        this.boxGroup.entityRemoved.subscribe(this.boxRemoved);
    }

    dispose() {
        this.stuff.entityAdded.unsubscribe(this.entityAdded);
        this.stuff.entityRemoved.unsubscribe(this.entityRemoved);
        this.boxGroup.entityAdded.unsubscribe(this.boxAdded);
        this.boxGroup.entityRemoved.unsubscribe(this.boxRemoved);

        this.ecs.eventBus.off('message', this.messageReceived);
        this.ecs.eventBus.off(EventMoveFinished, this.messageReceived);
    }

    messageReceived(data: any) {
        // don't think this belongs here
        // the renderer really doesn't care what spot a box is in
    }

    update(deltaTime: number) {
        super.update(deltaTime);
        for (let [entityId, renderInstance] of this.entities) {
            const entity = this.ecs.entityManager.getEntity(entityId);
            if (!entity) {
                throw new Error(`Entity ${entityId} not found`);
            }
            if (entity.isComponentDirty(TransformComponent)) {
                const position = entity.getComponent<TransformComponent>(TransformComponent)!;
                if (entity.name === 'player') {
                    renderInstance.setPosition(position.x, position.y - 64);
                }
                else {
                    renderInstance.setPosition(position.x, position.y);
                }
                entity.markComponentClean(TransformComponent);
            }
            if (entity.isComponentDirty(SpriteComponent)) {
                const canvasModel = entity.getComponent<SpriteComponent>(SpriteComponent)!;
                const fsd = this.animationManager.getEntityAnimation(canvasModel.item);
                //console.log('fsd', canvasModel.item, canvasModel.animation, fsd);
                if (!fsd.animation.hasOwnProperty(canvasModel.animation)) {
                    this.logger.warn(`Animation ${canvasModel.animation} not found in ${canvasModel.item}`);
                } else {
                    renderInstance.frames = fsd.animation[canvasModel.animation];
                    entity.markComponentClean(SpriteComponent);
                }
            }
            renderInstance.incrementFrame(deltaTime);
        }
        const camera = this.ecs.entityManager.getEntityByName('camera')!;
        this.renderEngine.beginFrame();
        this.renderEngine.render(camera.getComponent(CameraComponent)!);
    }

    private boxAdded(entityId: number) {
        this.boxEntities.add(entityId);
    }

    private boxRemoved(entityId: number) {
        this.boxEntities.delete(entityId);
    }

    private entityAdded(entityId: number) {
        const entity = this.ecs.entityManager.getEntity(entityId)!;
        const canvasModel = entity.getComponent<SpriteComponent>(SpriteComponent)!;
        const fsd = this.animationManager.getEntityAnimation(canvasModel.item);
        const ghj = fsd.animation[canvasModel.animation];
        if (!ghj) {
            this.logger.warn(`Animation ${canvasModel.animation} not found in ${canvasModel.item}`);
        }
        const renderInstance = new Sprite(fsd.spriteSheet!, !ghj ? [-1] : ghj);
        const position = this.ecs.entityManager.getEntity(entityId)!.getComponent<TransformComponent>(TransformComponent)!;
        renderInstance.setPosition(position.x, position.y);
        renderInstance.setScale(position.scale);
        this.renderEngine.registerGameObject(renderInstance);
        this.entities.set(entityId, renderInstance);
    }

    private entityRemoved(entityId: number) {
        this.renderEngine.unregisterGameObject(this.entities.get(entityId)!);
        this.entities.delete(entityId);
    }

}
