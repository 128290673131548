/** @jsx h */
import { h } from 'preact';
import { styled, keyframes } from 'goober';

const particleMove = keyframes`
  from {
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
  to {
    transform: translateY(-20px) translateX(-200px);
    opacity: 0;
  }
`;

const Particle = styled('div')`
    position: absolute;
    width: 3px;
    height: 3px;
    background-color: white;
    border-radius: 50%;
    animation: ${particleMove} 4s infinite ease-in-out;
    animation-delay: ${({ delay }) => delay}s;
    top: ${({ top }) => top}%;
    left: ${({ left }) => left}%;
    opacity: 0;
    z-index: 10;
`;

const FullScreenImage = styled('img')`
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const FullScreenParticlesComponent = () => {
    const particles = Array.from({ length: 15 }).map((_, index) => (
        <Particle key={index} delay={Math.random() * 5} top={Math.random() * 100} left={Math.random() * 100} />
    ));

    return (
        <div>
            {particles}
        </div>
    );
};
