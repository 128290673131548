import {DiscreteGridComponent} from '../../../shared/src/ecs/discrete-grid-component.js';
import {Ecs} from '../../../shared/src/ecs/ecs.js';
import {TransformComponent} from '../../../shared/src/ecs/transform-component.js';
import {InputComponent} from '../graphics/input-component.js';
import {SokobanBoxComponent} from './sokoban-box-component.js';
import {SokobanImmovableComponent} from './sokoban-immovable-component.js';
import {SpriteComponent} from './sokoban-render-system.js';
import {SokobanTargetComponent} from './sokoban-target-component.js';
import {SokobanWorld} from './sokoban-world.js';


const createBox = (ecs: Ecs, x: number, y: number, item: string, onTarget: boolean) => {
    const entity2 = ecs.entityManager.createEntity();
    const sprite = new SpriteComponent(item);
    if (onTarget) {
        sprite.animation = 'onTarget';
    }
    entity2.setComponent(SpriteComponent, sprite);
    entity2.setComponent(TransformComponent, new TransformComponent(0, 0));
    entity2.setComponent(DiscreteGridComponent, new DiscreteGridComponent(x, y));
    entity2.setComponent(SokobanBoxComponent, new SokobanBoxComponent(false));
};

const createFloor = (ecs: Ecs, x: number, y: number, target: boolean) => {
    const entity3 = ecs.entityManager.createEntity();
    entity3.setComponent(SpriteComponent, new SpriteComponent(target ? 'target' : 'floor'));
    entity3.setComponent(TransformComponent, new TransformComponent(0, 0));
    entity3.setComponent(DiscreteGridComponent, new DiscreteGridComponent(x, y));
    if (target) {
        entity3.setComponent(SokobanTargetComponent, new SokobanTargetComponent());
    }
};

const createPlayer = (ecs: Ecs, x: number, y: number) => {
    const entity5 = ecs.entityManager.createEntity();
    entity5.name = 'player';
    const spriteComponent = new SpriteComponent('player');
    spriteComponent.animation = 'idle_left';
    entity5.setComponent(SpriteComponent, spriteComponent);
    entity5.setComponent(TransformComponent, new TransformComponent(0, 0));
    entity5.setComponent(DiscreteGridComponent, new DiscreteGridComponent(x, y));
    entity5.setComponent(InputComponent, new InputComponent());
};

const mapCharToSprite: Record<string, string> = {
    '#': 'wall',
    ' ': 'floor',
    '.': 'target',
    '*': 'box_on_target',
    '$': 'box',
    '@': 'player',
    'X': 'background',
    '+': 'player_on_target',
};

export const convertWorldToEntities = (ecs: Ecs, map: SokobanWorld) => {
    for (let y = 0; y < map.mapDimensions.height; y++) {
        for (let x = 0; x < map.mapDimensions.width; x++) {
            const entity = ecs.entityManager.createEntity();
            entity.name = `tile-${x}-${y}`;

            const tt = (mapCharToSprite)[map.get(x, y)];

            switch (tt) {
                case 'wall':
                    entity.setComponent(SpriteComponent, new SpriteComponent(tt));
                    entity.setComponent(TransformComponent, new TransformComponent(0, 0));
                    entity.setComponent(DiscreteGridComponent, new DiscreteGridComponent(x, y));
                    entity.setComponent(SokobanImmovableComponent, new SokobanImmovableComponent());
                    break;
                case 'floor':
                    createFloor(ecs, x, y, false);
                    break;
                case 'target':
                    createFloor(ecs, x, y, true);
                    break;
                case 'box_on_target':
                    createFloor(ecs, x, y, true);
                    break;
                case 'box':
                    createFloor(ecs, x, y, false);
                    break;
                case 'player':
                    createFloor(ecs, x, y, false);
                    break;
                case 'player_on_target':
                    createFloor(ecs, x, y, true);
                    break;
                default:
                    break;
            }
        }
    }

    let playerX = 0;
    let playerY = 0;

    for (let y = 0; y < map.mapDimensions.height; y++) {
        for (let x = 0; x < map.mapDimensions.width; x++) {
            const entity = ecs.entityManager.createEntity();
            entity.name = `tile-${x}-${y}`;
            const tt = mapCharToSprite[map.get(x, y)];
            switch (tt) {
                case 'box':
                    createBox(ecs, x, y, 'box', false);
                    break;
                case 'box_on_target':
                    createBox(ecs, x, y, 'box', true);
                    break;
                case 'player':
                case 'player_on_target':
                    playerX = x;
                    playerY = y;
                default:
                    break;
            }
        }
    }

    createPlayer(ecs, playerX, playerY);
};

