import { Canvas } from './canvas.js';
import { Rectangle2D } from './maths.js';

export class Viewport {
    public x: number;
    public y: number;
    public width: number;
    public height: number;

    constructor(x: number, y: number, width: number, height: number) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
    }

    static fromRect(rect: Rectangle2D): Viewport {
        return new Viewport(rect.x, rect.y, rect.w, rect.h);
    }
}
