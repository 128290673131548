import { IDisposable } from '../../../shared/src/common.js';
import { Ecs } from '../../../shared/src/ecs/ecs.js';
import { System } from '../../../shared/src/ecs/system.js';
import { Logger } from '../../../shared/src/Logger.js';
import { CameraComponent } from '../graphics/camera-component.js';
import { Canvas } from '../graphics/canvas.js';
import { InputComponent, KeyState, MouseButton } from '../graphics/input-component.js';
import { NewCommand } from './sokoban-game-command-dispatcher.js';
import { MoveCommand } from './sokoban-game-command.js';

interface Rect {
    x: number;
    y: number;
    width: number;
    height: number;
};

export class SokobanPlayerInputSystem extends System implements IDisposable {
    private referencePoint: { x: number, y: number } | null = null;

    constructor(private ecs: Ecs, private canvas: Canvas, logger: Logger) {
        super(logger);
    }

    dispose() {
    }

    mapKeyToDirection = (key: string): [number, number] => {
        switch (key) {
            case 'ArrowLeft':
                return [-1, 0];
            case 'ArrowRight':
                return [1, 0];
            case 'ArrowUp':
                return [0, -1];
            case 'ArrowDown':
                return [0, 1];
            default:
                return [0, 0];
        }
    };

    keyDirections = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];


    private getDirectionFromReferencePoint(currentPosition: { x: number, y: number }): [number, number] {
        const dx = currentPosition.x - this.referencePoint!.x;
        const dy = currentPosition.y - this.referencePoint!.y;
        // Determine direction based on the largest component of the vector
        if (Math.abs(dx) > Math.abs(dy)) {
            return dx > 0 ? [1, 0] : [-1, 0];
        } else {
            return dy > 0 ? [0, 1] : [0, -1];
        }
    }

    // Optional: A method to reset the reference point if needed
    resetReferencePoint(): void {
        this.referencePoint = null;
        console.log('Reference point reset.');
    }

    update(deltaTime: number) {
        const player = this.ecs.entityManager.getEntityByName('player');
        if (!player) {
            this.logger.warn('Player not found');
            return;
        }
        const camera = this.ecs.entityManager.getEntityByName('camera');
        if (!camera) {
            this.logger.warn('Camera not found');
            return;
        }
        const cami = camera.getComponent<CameraComponent>(CameraComponent)!;

        const input = player.getComponent<InputComponent>(InputComponent)!;

        // const leftButton = input.getMouseButtonState(MouseButton.Left);
        // if (leftButton === KeyState.Down) {
        //     // Determine direction based on the reference point
        //     const t = player.getComponent<TransformComponent>(TransformComponent)!;
        //     const t1 = screenToWorld([input.mousePosition.x, input.mousePosition.y], cami.matrix.getMatrix());
        //     this.referencePoint = t;
        //     const direction = this.getDirectionFromReferencePoint(t1);
        //     const command = new MoveCommand(player.id, direction[0], direction[1]);
        //     this.ecs.eventBus.emit(NewCommand, command);
        //
        // }

        for (let key of this.keyDirections) {
            const keyState = input.getKeyState(key);
            if (keyState === KeyState.Down) {
                const [dx, dy] = this.mapKeyToDirection(key);
                const command = new MoveCommand(player.id, dx, dy);
                this.ecs.eventBus.emit(NewCommand, command);
                break;
            }
        }
    }
}
