import {h} from 'preact';
import {useState} from 'preact/hooks';
import {styled} from 'goober';

// Styled components
export const DialogBox = styled('div')`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
    background-color: black;
    border: 3px solid white;
    box-sizing: border-box;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    text-align: center;
    z-index: 100;
`;

const StarContainer = styled('div')`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
`;

const Star = styled('span')`
    outline: none;
    -webkit-tap-highlight-color: transparent;
    font-size: 2rem;
    cursor: pointer;
    padding: 0 5px;

    &.filled {
        color: gold;
    }

    &.unfilled {
        color: gray;
    }
`;

// Reusable Text for Headers
export const HeaderText = styled('div')`
    font-size: 1.2em;
    margin-bottom: 20px;
`;

export const StatText = styled('div')`
    margin-bottom: 10px;
    .emoji {
        vertical-align: text-bottom;
    }
`;

export const ButtonContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    width: 100%;
`;

export const RetroButton = styled('button')`
    padding: 10px 20px;
    background: transparent;
    color: white;
    border: 3px solid white;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
        background-color: white;
        color: black;
    }

    &:active {
        transform: translate(1px, 1px);
    }

    &:focus {
        outline: none;
        border-color: #FFD700;
    }
`;

export const DifficultyRatingDialog = (props: { onSubmit: (no: number) => void }) => {
    const [rating, setRating] = useState(0);

    const handleStarClick = (index: number) => {
        setRating(index);
    };

    const handleSubmit = () => {
        props.onSubmit(rating);
    };

    const handleSkip = () => {
        props.onSubmit(0);
    }

    return (
        <DialogBox>
            <div>How difficult was it?</div>
            <StarContainer>
                {[1, 2, 3, 4, 5].map((star) => (
                    <Star
                        key={star}
                        className={rating >= star ? 'filled' : 'unfilled'}
                        onClick={() => handleStarClick(star)}
                    >
                        {rating >= star ? '★' : '☆'}
                    </Star>
                ))}
            </StarContainer>
            <ButtonContainer>
                <RetroButton onClick={handleSkip}>Skip</RetroButton>
                <RetroButton onClick={handleSubmit}>Submit</RetroButton>
            </ButtonContainer>
        </DialogBox>
    );
};
