import {route} from 'preact-router';
import {IDisposable} from '../../../shared/src/common.js';
import {Ecs} from '../../../shared/src/ecs/ecs.js';
import {System} from '../../../shared/src/ecs/system.js';
import {Logger} from '../../../shared/src/Logger.js';
import {TimeUtils} from '../../../shared/src/time-utils.js';
import {TimerManager} from '../../../shared/src/timer-manager.js';
import {AnimationManager} from '../graphics/animation-manager.js';
import {Canvas} from '../graphics/canvas.js';
import {InputEventType, InputSystem, InputSystemEvent} from '../graphics/input-system.js';
import {Rectangle2D} from '../graphics/maths.js';
import {SpriteSheetLoader} from '../graphics/sprite.js';
import {UiButtonSegmented} from '../graphics/ui-button-segmented.js';
import {UiButton} from '../graphics/ui-button.js';
import {UiContext} from '../graphics/ui-context.js';
import {UiEngine} from '../graphics/ui-engine.js';
import {UiLabel} from '../graphics/ui-label.js';
import {NewCommand} from './sokoban-game-command-dispatcher.js';
import {MoveCommand} from './sokoban-game-command.js';
import {SokobanGameInfoComponent} from './sokoban-game-info-component.js';
import {RestartCommand} from './sokoban-game-scene.js';
import {SokobanWorld} from './sokoban-world.js';

export class SokobanUiSystem extends System implements IDisposable {
    private uiEngine: UiEngine;
    private label!: UiLabel;
    private label2!: UiLabel;

    private timerManager: TimerManager;

    constructor(private ecs: Ecs,
                private sheetCache: SpriteSheetLoader,
                private animationManager: AnimationManager,
                private sokobanWorld: SokobanWorld,
                private canvas: Canvas,
                private inputSystem: InputSystem,
                logger: Logger) {
        super(logger);
        this.timerManager = new TimerManager();
        const uiContext = new UiContext(this.canvas, animationManager);
        this.uiEngine = new UiEngine(uiContext, logger);
        this.onInputEvent = this.onInputEvent.bind(this);
        this.inputSystem.onInputEvent.addHandlerFront(this.onInputEvent);
        this.init(uiContext);
    }

    dispose(): void {
        this.inputSystem.onInputEvent.removeHandler(this.onInputEvent);
    }

    mapKeyToDirection = (key: string): [number, number] => {
        switch (key) {
            case 'ArrowLeft':
                return [-1, 0];
            case 'ArrowRight':
                return [1, 0];
            case 'ArrowUp':
                return [0, -1];
            case 'ArrowDown':
                return [0, 1];
            default:
                return [0, 0];
        }
    };

    keyDirections = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];

    splitStringEveryNChars(text: string, n: number) {
        let result = "";
        for (let i = 0; i < text.length; i += n) {
            result += text.substring(i, i + n) + "\n"; // Add the substring and a newline
        }
        return result;
    }

    copyUrlToClipboard = async () => {
        const url = window.location.href;  // Get the current URL
        try {
            await navigator.clipboard.writeText(url);
            console.log('URL copied to clipboard!');
            this.label.text = 'copied';
        } catch (err) {
           // this.label.text = 'failed';
        }
        this.timerManager.setTimeout(() => {
            this.label.text = `Id: ${this.sokobanWorld.mapId}`;
        }, 1000);
    };

    public init(uiContext: UiContext): void {
        const rectToKeyMap: { rect: Rectangle2D, name: string }[] = [
            { rect: { x: 0, y: 260, w: 162, h: 162 }, name: 'ArrowLeft' },
            { rect: { x: 175, y: 88, w: 162, h: 162 }, name: 'ArrowUp' },
            { rect: { x: 174, y: 260, w: 162, h: 162 }, name: 'ArrowDown' },
            { rect: { x: 350, y: 260, w: 162, h: 162 }, name: 'ArrowRight' },
        ];
        const keysButton = new UiButtonSegmented(uiContext, 'keys', rectToKeyMap);
        keysButton.width = 200;
        keysButton.height = 200;
        keysButton.bottom = 0; // this sprite has padding
        keysButton.left = 30;
        keysButton.positionType = 'absolute';
        keysButton.onClick.subscribe((key) => {
            const player = this.ecs.entityManager.getEntityByName('player');
            if (!player) {
                this.logger.warn('Player not found');
                return;
            }
            const [dx, dy] = this.mapKeyToDirection(key);
            const command = new MoveCommand(player.id, dx, dy);
            this.ecs.eventBus.emit(NewCommand, command);
        });
        this.uiEngine.root.addChild(keysButton);

        const restartButton = new UiButton(uiContext, 'restart');
        restartButton.bottom = 30;
        restartButton.right = 30;
        restartButton.height = 75;
        restartButton.width = 75;
        restartButton.positionType = 'absolute';
        restartButton.onClick.subscribe(() => {
            this.ecs.eventBus.emit(RestartCommand, {});
        });
        this.uiEngine.root.addChild(restartButton);

        const changeTheme = new UiButton(uiContext, 'restart');
        changeTheme.bottom = 30;
        changeTheme.right = 120;
        changeTheme.height = 75;
        changeTheme.width = 75;
        changeTheme.positionType = 'absolute';
        changeTheme.onClick.subscribe(() => {
            const currentTheme = localStorage.getItem('theme') ?? 'i1';
            localStorage.setItem('theme', currentTheme === 'i1' ? 'i1_2' : 'i1');
            window.location.reload();
            //route(window.location.pathname, false);
            //this.ecs.eventBus.emit(RestartCommand, {});
        });
        this.uiEngine.root.addChild(changeTheme);

        this.label = new UiLabel(uiContext);
        this.label.text = `Id: ${this.sokobanWorld.mapId}`;
        this.label.top = 30;
        this.label.right = 30;
        this.label.positionType = 'absolute';
        this.label.onClick.subscribe(() => {
            this.copyUrlToClipboard();
        });
        this.uiEngine.root.addChild(this.label);

        this.label2 = new UiLabel(uiContext);
        this.label2.text = 'Steps: 5';
        this.label2.top = 30;
        this.label2.left = 30;
        this.label2.positionType = 'absolute';
        this.uiEngine.root.addChild(this.label2);
    }

    public update(delta: number): void {
        this.timerManager.update(delta);
        const gameInfo = this.ecs.entityManager.getEntityByName('gameInfo');
        const comp = gameInfo?.getComponent<SokobanGameInfoComponent>(SokobanGameInfoComponent)!;
        if (comp.gameRunning) {
            const currentStamp = TimeUtils.getCurrentTimestamp();
            const deltaStamp = currentStamp - comp.mapStartTimeStamp;
            if (comp.alreadySolved) {
                this.label2.text = 'previously\nsolved';
            } else {
                this.label2.text = `Steps: ${comp.steps ?? 0}${comp.alreadySolved ? '\nalready solved' : ('\n Time: ' + TimeUtils.formatDelta(deltaStamp))}`;
            }
        }
        this.uiEngine.render();
    }

    private onInputEvent(event: InputSystemEvent): boolean {
        if (event.type === InputEventType.MouseMove) {
            return true;
        }
        return this.uiEngine.onInputEvent(event);
    }
}
